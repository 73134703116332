import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { Stack, ScrollablePane, ScrollbarVisibility, Text, PrimaryButton, TextField } from '@fluentui/react';
import { appStyles, Logger, Utilities, history, UserStorageHelper, LocalStorageHelper } from '../helpers';
import { authActions } from '../store/actions/auth/actions';

const LoginPage = () => {
  const dispatch = useDispatch();
  const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
  const authentication = useSelector((state: RootStateOrAny) => state.authentication);
  const appState = useSelector((state: RootStateOrAny) => state.appReducer);
  const [url, setUrl] =  useState(''); 
  const [loadingUserConfiguration, setLoadingUserConfiguration] =  useState(true);
  const [isReadonlyUrl, setIsReadonlyUrl] =  useState(false); 

  useEffect(() => {
    if (authentication.loggedIn === true) {
      if (uiState.pageMode === 'link')
        history.replace('/link')
      else if (uiState.pageMode === 'settings')
        history.replace('/settings')
      else
        history.replace('/file');
    }
  }, [appState.appConfig.stubUrl, authentication.loggedIn])

  useEffect(() => {
    if (appState.appConfig.stubUrl != '') {
      if (appState.appConfig.nextcloudUrl != '') {
        setUrl(appState.appConfig.nextcloudUrl);
        setIsReadonlyUrl(true);
      }
      else if (LocalStorageHelper.storeContainsKey(LocalStorageHelper.storeKeys.SERVER_URL))
        setUrl(LocalStorageHelper.storeGetObject(LocalStorageHelper.storeKeys.SERVER_URL));
    }
  }, [appState.appConfig.stubUrl, authentication.loggedIn])

  useEffect(() => {
    async function loadData() {
      if (appState.appConfig.stubUrl != '' && loadingUserConfiguration) {
        setLoadingUserConfiguration(false);
      }
    }
    loadData();
  }, [appState.appConfig.stubUrl])

  const signIn = () => {
    Logger.info(`login method called`);
    dispatch(authActions.signin(url.trim()));
  }
  
  return (
    <Stack>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}> 
        <Stack className="main-part">
          <Stack className={appStyles.globalStyles.pageHeaderLayout}>
            <Text className={appStyles.globalStyles.pageHeader}>{uiState.translate('login_header')}</Text>
          </Stack> 
          {authentication.loginFailed === true ?
            <Stack className={appStyles.globalStyles.errorLoginStack} horizontalAlign='stretch'>
              <Text className={appStyles.globalStyles.text}> {uiState.translate('login_error_init')} </Text>
            </Stack>
              :  
              <></>
          }
          <Stack horizontalAlign='stretch' tokens={{ childrenGap: 10 }}>
            <TextField styles={appStyles.textBoxStyle} label={ uiState.translate('login_url') } value={url} 
              placeholder={ uiState.translate('login_url_placeholder') } disabled={isReadonlyUrl}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => setUrl(e.target.value)}/>
            <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('login_sign_in') } disabled={url === ""} style={{ marginTop: 30 }} onClick={signIn} />
          </Stack>
        </Stack> 
      </ScrollablePane>          
    </Stack>
  )
}

export default LoginPage;
