/**
 * Component displays the button with predefined (onstants) values
 *
 */

 import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
 import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
 import PropTypes from 'prop-types'
 import { Stack, Text, IIconProps, IconButton, Dialog, TextField, DialogFooter, PrimaryButton, DialogType } from '@fluentui/react';
 import { appStyles} from '../../helpers';
 
 const WarningDialog = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);

    const dialogContentProps = {
      type: DialogType.close
    };

    const hideDialog = async (e) => {
        e.stopPropagation();
        props.onDismiss(true);
    }

    const warningIcon: IIconProps = {
        iconName: props.icon
      };

    return (
    <Stack > 
      <Dialog
          styles={appStyles.DialogStyle}
          hidden={props.hidden}
          onDismiss={hideDialog}
          dialogContentProps={dialogContentProps}>
        <Stack tokens={{ childrenGap: 15 }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
                <IconButton style={{color: props.color}} iconProps={warningIcon}/> 
                <Text className={appStyles.globalStyles.text}> {props.subText}</Text>
            </Stack>
            <DialogFooter>
              <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('warning_dialog_ok')} onClick={hideDialog} />
            </DialogFooter>
        </Stack>
      </Dialog>  
    </Stack>
   )
 }
 
 export default WarningDialog;
 
 WarningDialog.propTypes = {
    hidden: PropTypes.bool,
    subText: PropTypes.string,
    onDismiss: PropTypes.func,
    icon: PropTypes.string,
    color: PropTypes.string,
 }