/**
 * Component displays the button with predefined (onstants) values
 *
 */
import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import PropTypes from 'prop-types'
import { Stack, IIconProps, mergeStyleSets, Dialog, Text, DialogFooter, PrimaryButton, DialogType } from '@fluentui/react';
import { appStyles} from '../../helpers';
import { Utilities } from '../../helpers';

const FileOverwriteDialog = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);

    const dialogContentProps = {
      type: DialogType.close,
    };

    useEffect(() => {
    }, []);

    const classes = mergeStyleSets({
      iconError: {
        fontFamily: 'FabricMDL2Icons-3',
        color: 'white',
      }, 
      dismissIconError: {
        fontFamily: 'FabricMDL2Icons',
        color: 'white !important',
      }, 
    })

    const errorIcon: IIconProps = {
      iconName: 'ErrorBadge',
      styles: {
        root: classes.iconError
      }
    };
  
    const dismissErrorIcon: IIconProps = {
      iconName: 'Clear',
      styles: {
        root: classes.dismissIconError
      }
    };

    const save = async(overwrite: boolean) => {
      console.log(`The action save`)

      props.onDismiss(true);
      props.onOverwrite(overwrite);
    }

    
    const hideDialog = async (e) => {
      e.stopPropagation();
      props.onDismiss(true);
    }


    return (
    <Stack > 
      <Dialog
          styles={appStyles.DialogStyle}
          hidden={props.hidden}
          onDismiss={hideDialog}
          dialogContentProps={dialogContentProps}>

        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 10 }}>
              <Text className={appStyles.globalStyles.text}>{Utilities.formatString(uiState.translate('overwrite_file'), props.fileName, props.fileNewName)}</Text>
            </Stack>
        </Stack>
        <DialogFooter>
          <Stack horizontal horizontalAlign="space-between">
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('button_yes')} onClick={() => save(true)} />
              <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('button_no')} onClick={() => save(false)} />
            </Stack>
            <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('button_cancel')} onClick={hideDialog} />
          </Stack>
        </DialogFooter>
      </Dialog>  
    </Stack>
  )
}

export default FileOverwriteDialog;
 
FileOverwriteDialog.propTypes = {
  hidden: PropTypes.bool,
  fileName: PropTypes.string,
  fileNewName: PropTypes.string,
  onDismiss: PropTypes.func,
  onOverwrite: PropTypes.func,
}