/**
 * Contains utilities functions
 */

import {isNull, isUndefined} from 'lodash';
import moment from 'moment';

/**
 * Formats the string - use {0}, {1} ... for format parameters
 * @param str 
 * @param val 
 */
const formatString = (str: string, ...val: string[]): string => {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
}

/**
 * Checks is the object is null or undefined
 * @param obj 
 */
const isNANObject = (obj): boolean => {
  return isNull(obj) || isUndefined(obj);
}

/**
 * Checks is string empty
 * @param value 
 */
const isStringEmpty = (value): boolean => {
  if (isNANObject(value)) 
    return true;

  return value.length <= 0;
}

/**
 * Emulate sleep
 * @param ms 
 */
const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}


/**
 * Add zero to number if len(number) === 1
 * @param digit 
 */
const fillZeroes = (digit: number) => {
  if (digit < 10 && digit >= 0)
      return '0' + digit
  else if (digit < 0 && digit > -10)
      return '-0' + (-digit)
  return digit
}

/**
 * Converts API UTC date
 * @param date 
 * @param lang 
 */
 const formatPlannedDate = (date, lang) => {
    const m = moment(date, "YYYY.MM.DD hh:mm:ss");
    const plannedTime = m.toDate();
    const dayName = plannedTime.toLocaleDateString(lang, { weekday: 'long' })
    const planned_date = Utilities.fillZeroes(plannedTime.getDate()) + '.' + 
        (Utilities.fillZeroes(plannedTime.getMonth() + 1)) + '.' + plannedTime.getFullYear()
    const delta = plannedTime.getTimezoneOffset()
    plannedTime.setMinutes(plannedTime.getMinutes() - delta)
    const planned_time = Utilities.fillZeroes(plannedTime.getHours()) + ':' + Utilities.fillZeroes(plannedTime.getMinutes())

    return dayName + ', ' + planned_date + ' ' + planned_time;
}

/**
 * Gets local date from API UTC date
 * @param date 
 */
 const getLocalDate = (date): Date => {
  const m = moment(date, "YYYY.MM.DD hh:mm:ss");
  const plannedTime = m.toDate();
  const delta = plannedTime.getTimezoneOffset();
  plannedTime.setMinutes(plannedTime.getMinutes() - delta);

  return plannedTime;
}

/**
 * Convert date UTC date to local date 
 * @param date 
 */
 const convertToLocalDate = (date): Date => {
  const dt = new Date();
  const delta = dt.getTimezoneOffset();
  const dateLocal = new Date(date);
  dateLocal.setMinutes(dateLocal.getMinutes() - delta);

  return dateLocal;
}

/**
 * Convert local date to UTC date 
 * @param date 
 */
 const convertToUTCDate = (date): Date => {
  const dt = new Date();
  const delta = dt.getTimezoneOffset();
  const dateUTC = new Date(date);
  dateUTC.setMinutes(dateUTC.getMinutes() + delta);

  return dateUTC;
}

const parseDatetime = (date_s: any) => {
  if (Utilities.isNANObject(date_s))
    return '';
  let date = date_s;
  if (typeof date_s == 'string') {
    try {
      var timestamp = Date.parse(date_s);
      if (isNaN(timestamp) == false) {
        date = new Date(timestamp);
      }
      else
        return date_s;
    }
    catch (ex) {
      return date_s;
    }
  }
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + fillZeroes(date.getHours()) + ':' + fillZeroes(date.getMinutes()) + ':' + fillZeroes(date.getSeconds())
}

const formatLocaleDate = (date_s: any) => {
  if (Utilities.isNANObject(date_s))
    return '';
  let date = date_s;
  if (typeof date_s == 'string') {
    try {
      var timestamp = Date.parse(date_s);
      if (isNaN(timestamp) == false) {
        date = new Date(timestamp);
      }
      else
        return date_s;
    }
    catch (ex) {
      return date_s;
    }
  }
  return date.toLocaleDateString();
}

const formatDate = (date: any): string => {
  console.log('date ', date);
  if (date !== null && date !== undefined && date !== '')
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  else
    return '';
};

const formatUploadDate = (date: any): string => {
  console.log('date ', date);
  if (date !== null && date !== undefined && date !== '')
    return date.getFullYear() + '-' + fillZeroes(date.getMonth() + 1) + '-' +  fillZeroes(date.getDate()) 
  else
    return '';
};

const formatBodyDate = (date_s: any): string => {
  if (date_s !== null && date_s !== undefined && date_s !== '') {
    const date = new Date(date_s)
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2); 
  }
  else
    return '';
};

const getElementNameWithoutNS = (elementNameWithNS) => {
  return elementNameWithNS.substring(elementNameWithNS.indexOf(":") + 1);
}

const getElementsByTagName = (node, elementNameWithNS) => {
  const elementWithoutNS = getElementNameWithoutNS(elementNameWithNS);
  let retVal = node.getElementsByTagName(elementNameWithNS);
  if (retVal == null || retVal.length == 0) {
      retVal = node.getElementsByTagName(elementWithoutNS);
  }
  return [...retVal];
};


const getElementByTagName = (node, elementNameWithNS) => {
  if (isNull(node) || isUndefined(node))
      return null;

  const retVal = getElementsByTagName(node, elementNameWithNS);
  if (!isNull(retVal) && !isUndefined(retVal) && retVal.length > 0) {
      return retVal[0];
  }
  return null;
};

const getElementTextContent = (node) => {
  return (!isNull(node) && !isUndefined(node)) ? node.textContent : '';
}

const getElementAttributeId = (node) => {
  return (!isNull(node) && !isUndefined(node)) ? node.getAttribute('Id') : '';
}

const parseDateActivity = (date_s: any) => {
  if (Utilities.isNANObject(date_s))
    return '';
  let date = date_s;
  if (typeof date_s == 'string') {
    try {
      var timestamp = Date.parse(date_s);
      if (isNaN(timestamp) == false) {
        date = new Date(timestamp);
      }
      else
        return date_s;
    }
    catch (ex) {
      return date_s;
    }
  }
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2)
}

export const Utilities = {
  formatString,
  isNANObject,
  isStringEmpty,
  sleep,
  fillZeroes,
  formatPlannedDate,
  getLocalDate,
  convertToLocalDate,
  convertToUTCDate,
  parseDatetime,
  formatDate,
  formatUploadDate,
  formatBodyDate,
  getElementsByTagName,
  getElementByTagName,
  getElementTextContent,
  parseDateActivity,
  formatLocaleDate,
  getElementAttributeId
}