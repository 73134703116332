import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { Stack, IContextualMenuItemProps, ContextualMenu, Text, ScrollablePane, ScrollbarVisibility, 
  IContextualMenuItem, Image, ChoiceGroup, IChoiceGroupOption, Checkbox, PrimaryButton } from '@fluentui/react';
import { appStyles, Logger, Utilities, history, UserStorageHelper } from '../helpers';
import { OptionsButton } from '../components/common';
import { ServerFilesDialog, WarningDialog, ProgressDialog } from '../components/dialogs';
import { WizardPassword, WizardDate, WizardButtons, WizardOption, WizardOptionConfirm, WizardFiles } from '../components/wizard'
import { appActions } from '../store/actions/app/actions';
import { authActions } from '../store/actions/auth/actions';
import { validationApi } from '../services/validationApi';
import { map, cloneDeep, filter, forEach, toNumber } from 'lodash'
import { appConstants, NotificationStatus, AppErrorCode } from '../store/actions/app/actionTypes';
import { authConstants } from '../store/actions/auth/actionTypes';
import { License } from 'nextcloud-api';
import { PasswordItem } from '../store/models/password.model';
import { Tabs } from '../components/tabbed';

const ShareLinkPage = () => {
  const dispatch = useDispatch();
  const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
  const appState = useSelector((state: RootStateOrAny) => state.appReducer);
  const authentication = useSelector((state: RootStateOrAny) => state.authentication);

  const [ filesDialogHidden, setFilesDialogHidden ] = useState(true);
  const [ warningDialogHidden, setWarningDialogHidden ] = useState(true);

  const [ warningMessage, setWarningMessage ] = useState('');
  const [ license, setLicense ] = useState(null);
  const [ wizardPos, setWizardPos ] =  useState(0) 

  const onFilesValidation = async () => {
    if (appState.filesToUpload.length == 0) {
      return showWarning(uiState.translate('main_page_no_attach_selected'));
    }
    return true;
  }

  const wizard = {
    pages: [
      {
        id: "files",
        validation: onFilesValidation
      },
      {
        id: "confirm",
        validation: () => {return true}
      },
    ]
  };

  useEffect(() => {
    async function loadData() {
        if (appState.appConfig.stubUrl !== '') {
          if (authentication.loggedIn === false) {
            history.replace('/login');
          }
          else {
            if (authentication.userConfig === null)
              dispatch(authActions.loadUserConfiguration(authentication.user));
          }
        }
    }
    loadData();
  }, [appState.appConfig.stubUrl, authentication.loggedIn])

  useEffect(() => {
    async function loadData() {
        if (authentication.userConfig !== null) {
          dispatch({ type: appConstants.APP_FILES_TO_UPLOAD, payload: { filesToUpload: [] } });
          const license = new License(authentication.userConfig);
          setLicense(license);
          console.log('license.IsLicenseValid ', license.IsLicenseValid);
          if (!license.IsLicenseValid) {
            dispatch({ type: appConstants.APP_SHOW_NOTIFICATION, payload: { status: NotificationStatus.Error, errorCode:AppErrorCode.CustomError, 
              message: uiState.translate('license_notvalid') } });             
          }
        }
    }
    loadData();
  }, [authentication.userConfig])

  const onUpload = () => {
    Logger.info(`onUpload called`);
    if (appState.filesToUpload.length == 0) {
        setWarningMessage(uiState.translate('main_page_no_attach_selected'))
        setWarningDialogHidden(false)
    } else {
      dispatch(appActions.shareLink(appState.filesToUpload))
    }
  }

  const onSelectServerFile = (ev?: React.MouseEvent<HTMLButtonElement>, item?: IContextualMenuItem): void => {
    dispatch(appActions.getServerFiles(''));
    setFilesDialogHidden(false)
    Logger.info(`onSelectServerFile called`);
  }

  const showWarning = (message: string): boolean => {
    if (message === '')
      return true;
    setWarningMessage(message);
    setWarningDialogHidden(false);
    return false;
  }

  const onNext = async () => {
    if (await wizard.pages[wizardPos].validation()) {
      let newPos = wizardPos + 1;
      setWizardPos(newPos);
    }
  }

  const onPrev = () => {
    setWizardPos(wizardPos - 1);
  }

  return (
    <Stack>
      <Tabs activeTab="link" />
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} className={appStyles.globalStyles.scrollWizard}> 
        <Stack className="main-part">
          {authentication.user !== null &&  authentication.userConfig !== null ?
          <Stack>
            {wizard.pages[wizardPos].id === 'files' ?
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack className={appStyles.globalStyles.pageHeaderLayout} style={{ textAlign: 'left' }}>
                <Text className={appStyles.globalStyles.pageHeader}>{uiState.translate('sharefiles_link')}</Text>
              </Stack>
              <Text className={appStyles.globalStyles.label} style={{ marginTop: 0 }}> {uiState.translate('sharefiles_desc')}</Text>
              <Stack horizontal>  
                <PrimaryButton styles={appStyles.primaryButton} style={{ width: '100%' }} onClick={onSelectServerFile}
                  text={uiState.translate('sharefiles_link') } />
              </Stack>
              <Stack tokens={{ childrenGap: 15 }}>
                <WizardFiles wizardPos={-1} onEdit={setWizardPos} />
              </Stack>  
            </Stack>
            :
            <Stack>
              <Stack className={appStyles.globalStyles.pageHeaderLayout} style={{ textAlign: 'left', marginBottom: 0 }}>
                <Text className={appStyles.globalStyles.pageHeader}>{uiState.translate('sharefiles_link')}</Text>
              </Stack>
              <WizardFiles wizardPos={0} onEdit={setWizardPos} />
            </Stack>
            }

          </Stack>:
          <></> 
          }
          <Stack>
            <ServerFilesDialog
              hidden={filesDialogHidden}
              onDismiss={setFilesDialogHidden}
              renderFiles={true} />
            <WarningDialog
              hidden={warningDialogHidden}
              subText={warningMessage}
              icon={"ErrorBadge"}
              color={"red"}
              onDismiss={setWarningDialogHidden} />
          </Stack>
        </Stack> 
      </ScrollablePane>
      {wizardPos >= 0 ?
      <Stack className={appStyles.globalStyles.wizardButtons}>
        {authentication.user !== null &&  authentication.userConfig !== null && wizardPos !== wizard.pages.length - 1 ?
        <WizardButtons wizardPos={wizardPos} wizardCount={wizard.pages.length} onNext={onNext} onPrev={onPrev} />
        : authentication.user !== null &&  authentication.userConfig !== null && wizardPos === wizard.pages.length - 1 ?
        <Stack className={appStyles.globalStyles.sectionLayout20}>
          <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('sharefiles_upload') } onClick={onUpload}/>
        </Stack>
        : <></>
        }
      </Stack>
      : <></>
      }                 
    </Stack>
  )
}

export default ShareLinkPage;
