import { store } from '../store';
import { Utilities, Logger } from '../helpers';
import { passwordApi } from 'nextcloud-api'

const validatePassword = async (password) => {
    const state = store.getState();

    if (!state.authentication.filesSharingSettings.PasswordEnabled && Utilities.isStringEmpty(password)) {
      return '';
    }

    const passwordValidateResult = await passwordApi.validatePassword(state.appReducer.appConfig.stubUrl, state.authentication.user, password);
    if (passwordValidateResult.success === true && passwordValidateResult.result.ocs.data.passed === true) {
      return '';
    }
    else {
      return passwordValidateResult.result.ocs.data.reason;
    }
}

const validateDate = async (date) => {
    return '';
}

const canSkip = (id: string, data: any) => {
    switch (id) {
      case "password":
        return Utilities.isStringEmpty(data.password) ? false : true;    
      case "date":
        return Utilities.isNANObject(data.date) || data.date === '' ? false : true;  
    }
    return false;
}

export const validationApi = {
    validatePassword,
    validateDate,
    canSkip
};