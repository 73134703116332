/**
 * Core application UI actions
 */

import { findIndex } from 'lodash';
import { store } from '../../../store';
import { history, LocalStorageHelper } from '../../../helpers';
import { Logger } from '../../../helpers/logger';
import { uiConstants } from './actionTypes';
import { appApi } from '../../../services/appApi';
import queryString from 'query-string'

//////////////////////////////////////////////////////////////////////////
//
// Helper functions
//

const _initializeUiAddin = payload => ({
    type: uiConstants.UI_INITIALIZE_ADDIN,
    payload
});

/**
 * Initializes the addin
 * @param inputLocation 
 */
const initializeAddinUi = (inputLocation: Location) => {
    return async dispatch => {
        Logger.info('initializeAddinUi action called');
        let displayLanguage = LocalStorageHelper.storeContainsKey(LocalStorageHelper.storeKeys.LANG) ? 
            LocalStorageHelper.storeGetObject(LocalStorageHelper.storeKeys.LANG) : 'en';

        const queryStringParsed = queryString.parse(inputLocation.search);
        const pageMode = queryStringParsed.page;

		// Need to add logic to check is language isn't in the list of supported languages - select "EN" then
        const config = await appApi.getConfig();
        if (findIndex(config.supportedLanguages, item => { return item === displayLanguage; }) < 0)
            displayLanguage = 'en'

        dispatch(_initializeUiAddin({ displayLanguage: displayLanguage, pageMode: pageMode })); 
    };
}

export const uiActions = {
    initializeAddinUi
};
