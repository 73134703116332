import locals from './locals/lang.json';

export const getTranslation = (lang: string) => (key: string) => {
  if (!locals[key]) 
    return key;
  const translation = locals[key][lang] ? locals[key][lang] : locals[key]['en'];
  if (translation === null || translation === undefined) {
    return key;
  }
  return translation.text;
}

export interface IAvailableLanguage {
  key: string;
  lang: string;
}

export const availableLanguages: IAvailableLanguage[] = [
  {
    key: 'en',
    lang: 'English'
  }
];
