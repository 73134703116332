/**
 * Component displays the progress bar
 *
 */

 import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
 import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
 import PropTypes from 'prop-types'
 import { Stack, Text, IIconProps, IconButton, Dialog, TextField, DialogFooter, PrimaryButton, DialogType } from '@fluentui/react';
 import { appStyles} from '../../helpers';
 import { store } from '../../store';
 
 const ProgressDialog = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const dispatch = useDispatch();
    const state = store.getState();
    const countFiles = state.appReducer.filesToUpload.length
    const subText = props.subText !== '' ? countFiles + props.subText : ''

    const hideDialog = async (e) => {
        e.stopPropagation();
        props.onDismiss(true);
    }

    const getTotalSize = () => {
        const sizes = []
        for(var i = 0; i < state.appReducer.filesToUpload.length; i++) {
          if (state.appReducer.filesToUpload[i].content === undefined)
            sizes.push(state.appReducer.filesToUpload[i].contentlength)
          else 
            sizes.push(state.appReducer.filesToUpload[i].content.byteLength)
        }
        
        var sum = 0;
        for (var i = 0; i < sizes.length; i++) {
          sum += sizes[i];
        }
        if (sum === 0)
            return 100;
        return sum;
    }

    return (
    <Stack > 
      <Dialog
          styles={appStyles.DialogStyle}
          hidden={props.hidden}
          onDismiss={hideDialog}>
        <Stack tokens={{ childrenGap: 15 }}>
            <Stack tokens={{ childrenGap: 10 }}>
                <Text className={appStyles.globalStyles.label}> {props.title}</Text>
                <Text className={appStyles.globalStyles.comment}> { subText }</Text>
                <Stack horizontalAlign="center" verticalAlign="center" >
                  <progress value={ state.appReducer.uploadedSize + state.appReducer.progressSize } 
                    max={ getTotalSize() } style={{ width: '100%', height: 8}}></progress>
              </Stack>
            </Stack>
            <DialogFooter>
              <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('progress_cancel')} onClick={hideDialog} />
            </DialogFooter>
        </Stack>
      </Dialog>  
    </Stack>
   )
 }
 
 export default ProgressDialog;
 
 ProgressDialog.propTypes = {
    hidden: PropTypes.bool,
    onDismiss: PropTypes.func,
    title: PropTypes.string,
    subText: PropTypes.string
 }