


/**
 * Component displays the button with predefined (onstants) values
 *
 */

 import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
 import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
 import PropTypes from 'prop-types'
 import { Stack, Text, Image, IImageProps, Dialog, TextField, DialogFooter, PrimaryButton, DialogType } from '@fluentui/react';
 import { appStyles, Utilities} from '../../helpers';
 import * as path from 'path'
 
 const File = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);

    const getImageByType = (contenttype: string, type: string, name: string, shared?: boolean) => {

        let img = ''
        if (type === 'email') {
          img = 'assets/email.png'
        }
        else {
          if (Utilities.isNANObject(contenttype)) {
            if (type === 'file') {
              const ext = path.extname(name).toLowerCase()
              switch (ext) {
                case ".pdf":
                  img = 'assets/application-pdf.png'
                  break;
                case ".mp4":
                  img = 'assets/video.png'
                  break;
                case ".xls":
                case ".xlsx":                  
                  img = 'assets/x-office-spreadsheet.png'
                  break;
                case ".ppt":
                case ".pptx":  
                  img = 'assets/x-office-presentation.png'
                  break;
                case ".doc":
                case ".docx":                   
                  img = 'assets/x-office-document.png'
                  break;
                case ".txt":
                  img = 'assets/text.png'
                  break;
                case ".jpg":
                case ".jpeg":                    
                  img = 'assets/file.png'
                  break;
                default:   
                  img = 'assets/file.png'
              }
            }
            else {
              if (!Utilities.isNANObject(shared) && shared)
                img = 'assets/dirshare.svg'
              else
                img = 'assets/dir2.svg'
            }
          }
          else {
            switch (contenttype) {
              case "application/pdf":
                img = 'assets/application-pdf.png'
                break;
              case "video/mp4":
                img = 'assets/video.png'
                break;
              case "application/vnd.oasis.opendocument.spreadsheet":
                img = 'assets/x-office-spreadsheet.png'
                break;
              case "application/vnd.oasis.opendocument.presentation":
                img = 'assets/x-office-presentation.png'
                break;
              case "application/vnd.oasis.opendocument.text":
                img = 'assets/x-office-document.png'
                break;
              case "application/octet-stream":
                img = 'assets/text.png'
                break;
              case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                img = 'assets/text.png'
                break;
              case "image/jpeg":
                img = 'assets/file.png'
                break;
              default:   
                img = 'assets/file.png'
            }
          }
        }
        const ControlImage: IImageProps = {
          src: img,
          height: "22px"
        }
        return ControlImage
      }
    

    return <Stack horizontal key={props.item.name} tokens={{ childrenGap: 5 }}> 
        <Stack style={{width: "25px"}} verticalAlign="center"> 
          <Image {...getImageByType(props.item.contenttype, props.item.type, props.item.name, props.item.shared)} /> 
        </Stack>
        <Stack verticalAlign="center">
          <Text className={appStyles.globalStyles.file}> {props.displayPath === true ? `${props.item.parentPath}/${props.item.name}` : props.item.name}</Text>
          <Text className={appStyles.globalStyles.date}>  {Utilities.parseDatetime(props.item.lastmod)}</Text>
          {!Utilities.isNANObject(props.item.content) && !Utilities.isNANObject(props.destinationFolder) ?
            <Text className={appStyles.globalStyles.date}>{ `${props.destinationFolder.parentPath}/${props.destinationFolder.name}` }</Text>
            :
            <></>
          }
        </Stack>
      </Stack>
 }
 
 export default File;
 
 File.propTypes = {
    item: PropTypes.any,
    displayPath: PropTypes.bool,
    destinationFolder: PropTypes.any
 }