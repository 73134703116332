 import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
 import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
 import PropTypes from 'prop-types'
 import { Stack, Text, TextField, PrimaryButton } from '@fluentui/react';
 import { appStyles, Utilities} from '../../helpers';
 import { passwordApi } from 'nextcloud-api'
 import { default as WizardOption } from './option';
 
 const WizardPassword = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const appState = useSelector((state: RootStateOrAny) => state.appReducer);
    const authentication = useSelector((state: RootStateOrAny) => state.authentication);
    const [pswd, setPswd] =  useState(props.value); 

    useEffect(() => {
      setPswd(props.value);
    }, [props.value]);

    const changePswd = (pswd: string) => {
      setPswd(pswd);
      props.onChange(pswd);
    }

    const generatePswd = async () => {
      console.log(`The action generatePswd`)
      const passwordResult = await passwordApi.generatePassword(appState.appConfig.stubUrl, authentication.user);
      if (passwordResult.success === true) {
        setPswd(passwordResult.result.ocs.data.password);
        props.onChange(passwordResult.result.ocs.data.password)
      }
    }

    return (
    <Stack > 
      <Stack className={appStyles.globalStyles.pageHeaderLayout} style={{ textAlign: 'left' }}>
        <Text className={appStyles.globalStyles.pageHeader}>{uiState.translate('sharefiles_pswd_header')}</Text>
      </Stack>
      <Stack className={appStyles.globalStyles.wizardContent} tokens={{ childrenGap: 10 }}>
          <TextField styles={appStyles.textBoxStyle} value={pswd} placeholder={uiState.translate('sharefiles_pswd_placeholder')}
              label={uiState.translate('sharefiles_pswd_label')}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => changePswd(e.target.value)}/>
          <Stack horizontalAlign='end'>
            <PrimaryButton styles={appStyles.primaryButtonReverse} text={uiState.translate('pswd_dialog_generate') }  onClick={generatePswd} />
          </Stack>
      </Stack>
      <Stack horizontal className={appStyles.globalStyles.sectionLayoutBorder}>
        <WizardOption value={pswd} notSelectedText={uiState.translate('share_nopassword')} />
      </Stack>
    </Stack>
   )
 }
 
 export default WizardPassword;
 
 WizardPassword.propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func
 }