/**
 * Main application actions types
 */

 export enum NotificationStatus {
    None = 'None',
    Success = 'Success',
    Error = 'Error',
    Warning = 'Warning',
}

export enum AppErrorCode {
    AuthenticationFailed = 'AuthenticationFailed',
    CustomError = 'CustomError'
}

export const appConstants = {
    APP_INITIALIZE_ADDIN: 'app.APP_INITIALIZE_ADDIN',
    APP_START_FETCH: 'app.APP_START_FETCH',
    APP_STOP_FETCH: 'app.APP_STOP_FETCH',
    APP_CLEAR_NOTIFICATION: 'app.APP_CLEAR_NOTIFICATION', 
    APP_SHOW_NOTIFICATION: 'app.APP_SHOW_NOTIFICATION',
    APP_SERVER_FILES: 'app.APP_SERVER_FILES',
    APP_FILES_TO_UPLOAD: 'app.APP_FILES_TO_UPLOAD',
    APP_DESTINATION_FOLDER: 'app.APP_DESTINATION_FOLDER',
    APP_UPLOADED_SIZE: 'users.APP_UPLOADED_SIZE',
    APP_PROGRESS_SIZE: 'users.APP_PROGRESS_SIZE',
    APP_TRANSFER_COMPLETED: 'app.APP_TRANSFER_COMPLETED',
    APP_LOG: 'app.APP_LOG',
    APP_DIALOG_PARAMS: 'app.APP_DIALOG_PARAMS',
    APP_SEARCH_RESULT: 'app.APP_SEARCH_RESULT'
}
