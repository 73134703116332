import React from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { Stack, Text, Link } from '@fluentui/react';
import { Utilities } from '../../helpers';

const Footer = () => {
  const appState = useSelector((state: RootStateOrAny) => state.appReducer);
  const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
  const dispatch = useDispatch();

  const styles = {
    root: {
      paddingLeft: 10,
      paddingRight: 10,
      width: '100%'
    }
  };
    
  const linkStyles = {
    root: {
      textDecoration: 'none'
    }
  };

  return (
    <div className='footer'>
      <Stack horizontal horizontalAlign='space-between' verticalAlign='center' styles={ styles }>
        <Text>{ Utilities.formatString(uiState.translate('footer_version_text_fmt'), appState.appConfig.version) }</Text>
        <Link href={appState.appConfig.organizationPage} target='_blank' styles={ linkStyles }>{ uiState.translate('footer_copyright_text') }</Link>
      </Stack>
    </div>
  )
}

export default Footer;
