/**
 * Contains helper functions to log the application logic
 */
 
interface LogEntry {
    level: string;
    message: string;
} 

enum Methods {
    debug = 'debug',
    error = 'error',
    info = 'info',
    warn = 'warn',
};

const log = (logEntry: LogEntry) => {
    const mappedMethod = Methods[logEntry.level];
    console[mappedMethod](logEntry.message);
}

const info = (message) => {
    log( {
        level: Methods.info,
        message
    });
}

const debug = (message) => {
    log( {
        level: Methods.debug,
        message
    });
}

const error = (message) => {
    log( {
        level: Methods.error,
        message
    });
}

const warn = (message) => {
    log( {
        level: Methods.warn,
        message
    });
}
    
export const Logger = {
    info,
    debug,
    error,
    warn
}