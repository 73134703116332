import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { Stack, Text, ScrollablePane, ScrollbarVisibility, PrimaryButton, FontIcon, Spinner, SpinnerSize, Link, DefaultButton, TextField, IIconProps, SelectionMode } from '@fluentui/react';
import { appStyles, Logger, Utilities, history, LocalStorageHelper } from '../helpers';
import { authActions } from '../store/actions/auth/actions';
import { DropdownWrapper } from '../components/common';
import { appApi } from '../services/appApi';
import { cloneDeep, forEach, find, map, toString, filter }  from 'lodash';
import { authConstants } from '../store/actions/auth/actionTypes';
import { uiConstants } from '../store/actions/ui/actionTypes';
import { Tabs } from '../components/tabbed';

const SettingsPage = () => {
  const dispatch = useDispatch();
  const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
  const appState = useSelector((state: RootStateOrAny) => state.appReducer);
  const authentication = useSelector((state: RootStateOrAny) => state.authentication);
  const [lang, setLang] = useState('en');

  useEffect(() => {
    async function loadData() {
      if (appState.appConfig.stubUrl !== '') {
        if (authentication.loggedIn === false) {
          history.replace('/login');
        }
        else {
          if (authentication.userConfig === null)
            dispatch(authActions.loadUserConfiguration(authentication.user));
        }
      }
    }
    loadData();
  }, [appState.appConfig.stubUrl, authentication.loggedIn])

  useEffect(() => {
    async function loadData() {
      if (!Utilities.isNANObject(authentication.userSettings)) {
        if (uiState.lang !== '') {
          setLang(uiState.lang);
        }
      }
    }
    loadData();
  }, [authentication.userSettings, uiState.lang])

  const renderSettings = () => {
    const language = find(authentication.userSettings, control => {
      return control.name === 'DefaultLanguage'
    })
    return <Stack tokens={{ childrenGap: 10 }}>

      <TextField styles={appStyles.textBoxStyle} label={ uiState.translate('settings_server_url')} value={authentication.user.server} readOnly></TextField>
      <TextField styles={appStyles.textBoxStyle} label={ uiState.translate('settings_account')} value={authentication.user.loginName} readOnly></TextField>
      <DropdownWrapper key={language.name}
            title={uiState.translate(language.resource)}
            selectedKey={lang}
            onSelectionChanged={onChangedLang}
            settingsItem={language}
            valueList={map(language.values, item => { return {key: toString(item.value), text: uiState.translate(item.title)  };} )}
          />

      <Stack horizontalAlign='start'>
        <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('logout') } onClick={onLogout} />
      </Stack>

    </Stack>
  }

  const onChangedLang = (key: string, settingsItem: any) => {
    console.log('onChangedLang called', key);
    setLang(key);
    let userSettings = cloneDeep(authentication.userSettings);
    let language = find(userSettings, control => {
      return control.name === 'DefaultLanguage'
    });
    console.log('language', language);
    language.value = key;
    LocalStorageHelper.storeSetObject(LocalStorageHelper.storeKeys.LANG, key);
    dispatch({ type: uiConstants.APP_CHANGE_LANG, payload: { displayLanguage: key} });

    dispatch({ type: authConstants.APP_APPLY_USER_SETTINGS, payload: { userSettings: userSettings } });
    const userCache = appApi.getCacheUserConfiguration();
    if (!Utilities.isNANObject(userCache)) {
      userCache.userSettings = userSettings;
      appApi.setCacheUserConfiguration(userCache);
    }
  }

  const onLogout = (): void => {
    console.log('onLogout method called');
    dispatch(authActions.logout(true));
  }
  

  return (
    <Stack>
      <Tabs activeTab="settings" />
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} className={appStyles.globalStyles.scrollWizardNoConfirm} > 
        <Stack className="main-part">
          {authentication.user !== null &&  authentication.userConfig !== null && authentication.userSettings !== null ?
          <Stack>
            <Stack className={appStyles.globalStyles.pageHeaderLayout}>
              <Text className={appStyles.globalStyles.pageHeader}>{uiState.translate('settings_header')}</Text>
            </Stack>  
            { renderSettings() }
          </Stack>
          :<></>
          }
        </Stack> 
      </ScrollablePane>          
    </Stack>
  )
}

export default SettingsPage;
