import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { ScrollablePane, ScrollbarVisibility, Stack } from '@fluentui/react';
import queryString from 'query-string';
import { Logger } from './helpers/logger';
import { appActions } from './store/actions/app/actions';
import { uiActions } from './store/actions/ui/actions';
import { Footer, Waiting, NotificationBar} from './components/common';
import { UserStorageHelper} from './helpers'
import { ShareFilePage, LoginPage, SettingsPage, ShareLinkPage } from './pages';
import { appStyles, LocalStorageHelper } from './helpers';


const App = (props) => {

  const appState = useSelector((state: RootStateOrAny) => state.appReducer);
  const authentication = useSelector((state: RootStateOrAny) => state.authentication);

  const dispatch = useDispatch();

  useEffect(() => {
    Logger.info('Initialized the application mainpage');
    Logger.debug('props.location=' + props.location);
    dispatch(uiActions.initializeAddinUi(props.location));
    dispatch(appActions.initializeAddin(props.location))
  }, [])

  const transformLocation = (inputLocation: Location): Location => {
    Logger.info(`transformLocation called with location.Search == ${inputLocation.search}; location = ${inputLocation}`);
    const queryStringParsed = queryString.parse(inputLocation.search);
    Logger.info(`queryStringParsed.page == ${queryStringParsed.page}`);
    Logger.info(`inputLocation.hash == ${inputLocation.hash}`);
    Logger.info(`UserStorageHelper.storeHasUser() == ${UserStorageHelper.storeHasUser()}`);

   
    if (inputLocation.hash == '#/login') {
      return {
        ...inputLocation,
        pathname: "/login"
      };
    }

    if (inputLocation.hash == '#/settings') {
      return {
        ...inputLocation,
        pathname: "/settings"
      };
    }

    if (inputLocation.hash == '#/file') {
      return {
        ...inputLocation,
        pathname: "/file"
      };
    }

    if (inputLocation.hash == '#/link') {
      return {
        ...inputLocation,
        pathname: "/link"
      };
    }

    return {
      ...inputLocation,
      pathname: '/' + (queryStringParsed.page === '' || queryStringParsed.page === null || queryStringParsed.page === undefined ? 'main' : queryStringParsed.page)
    };

  }

  return (
    <div className='wrapper'>
      <Router>
        <NotificationBar />
        <div className='main'>
          <Route render={() => (
            <Switch location={transformLocation(props.location)}>
              <Route path="/login" component={LoginPage} />
              <Route path="/settings" component={SettingsPage} />
              <Route path="/file" component={ShareFilePage} />
              <Route path="/link" component={ShareLinkPage} />
              <Redirect from="*" to="/file" />
            </Switch>
          )}
          />
        </div>
        <Footer />
        <Waiting />        
      </Router>
    </div>
  )
}

export default App;
