import App from "../app";
import { initializeIcons } from '@uifabric/icons';
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import { store } from '../store/';
import { enableES5} from 'immer'

enableES5();
initializeIcons();

let addinLocation = null;

const render = Component => {
	console.log('start reder');
	ReactDOM.render(
		<React.StrictMode>
		  <Provider store={store}>
		  <Component isOfficeInitialized={true} location={addinLocation} />
		  </Provider>
		</React.StrictMode>,
		document.getElementById('container')
	);
};

addinLocation = window.location;
render(App);





