/**
 * Application api implementation
 */
 
import axios from 'axios';
import queryString from 'query-string';
import { store } from '../store';
import { Utilities, Logger, LocalStorageHelper, Crypto} from '../helpers';

export enum AppErrorCode {
    CustomError = 'CustomError',
}

 /**
  * Load config
  */
const getConfig = async () => {
    console.log('getConfig is called');
    const state = store.getState();
    if (state.appReducer.appConfig.appName.length <= 0) {
        return (await axios.get('assets/config.json')).data;
    }
    return state.appReducer.appConfig;
}

/**
 * Load settings config
 */
 const getSettingsConfig = async () => {
    console.log('getSettingsConfig is called');
    return (await axios.get('assets/settings.json')).data;
}

function getCacheUserConfiguration() {
    try {
        const state = store.getState();
        if (LocalStorageHelper.storeContainsKey(LocalStorageHelper.storeKeys.CACHE_USER_CONFIGURATION)) {
            const userCache =  JSON.parse(Crypto.decrypt(LocalStorageHelper.storeGetObject(LocalStorageHelper.storeKeys.CACHE_USER_CONFIGURATION)));
            const dateAdded = new Date(userCache.dateAdded);
            dateAdded.setMinutes(dateAdded.getMinutes() + state.appReducer.appConfig.cacheTime);
            const dt = new Date();
            if (dateAdded.getTime() >= dt.getTime()) {
                return userCache;
            }
        }
    }
    catch (ex) {
        console.log('Error: ', ex.message);
    }
    return null;
}

function setCacheUserConfiguration(userCache) {
    try {
        LocalStorageHelper.storeSetObject(LocalStorageHelper.storeKeys.CACHE_USER_CONFIGURATION, Crypto.encrypt(JSON.stringify(userCache)));
    }
    catch (ex) {
        console.log('Error: ', ex.message);
    }
}

function clearCacheUserConfiguration() {
    try {
        const state = store.getState();
        if (LocalStorageHelper.storeContainsKey(LocalStorageHelper.storeKeys.CACHE_USER_CONFIGURATION)) {
            LocalStorageHelper.storeRemoveKey(LocalStorageHelper.storeKeys.CACHE_USER_CONFIGURATION);
        }
    }
    catch (ex) {
        console.log('Error: ', ex.message);
    }
}

export const appApi = {
    getConfig,
    getSettingsConfig,
    getCacheUserConfiguration,
    setCacheUserConfiguration,
    clearCacheUserConfiguration
}

