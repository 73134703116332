 import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
 import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
 import PropTypes from 'prop-types'
 import { Stack, Text, ActionButton } from '@fluentui/react';
 import { appStyles} from '../../helpers';
 import { default as WizardOption } from './option';
 
 const WizardOptionConfirm = (props) => {
  const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);

  const onEdit = () => {
    props.onEdit(props.wizardPos);
  }

  return (
    <Stack className={appStyles.globalStyles.sectionLayoutBorder} tokens={{ childrenGap: 10 }}>
      <Stack horizontal verticalAlign='center' horizontalAlign='space-between'>
        <Text className={appStyles.globalStyles.text}>{props.title}</Text>
        <ActionButton styles={appStyles.actionButtonEdit} text={uiState.translate('button_edit')}
          onClick={onEdit} />
      </Stack>
      <WizardOption value={props.value} notSelectedText={props.notSelectedText} />
    </Stack>
  )
 }
 
 export default WizardOptionConfirm;
 
 WizardOptionConfirm.propTypes = {
    value: PropTypes.string,
    title: PropTypes.string,
    notSelectedText: PropTypes.string,
    wizardPos: PropTypes.number,
    onEdit: PropTypes.func
 }