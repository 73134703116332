import produce from 'immer';
import { getTranslation } from '../../../helpers/translation';
import { uiConstants } from '../../actions/ui/actionTypes';
import { LocalStorageHelper } from '../../../helpers';

const initialState = { 
  lang: localStorage.getItem('lang') || 'en',
  translate: getTranslation('en'),
  backPage: '',
  pageMode: ''
};

export function uiReducer(state = initialState, action) {
  switch (action.type) {
    case uiConstants.UI_INITIALIZE_ADDIN:
		return produce(state, draft => {
			draft.translate = getTranslation(action.payload.displayLanguage);
			draft.lang = action.payload.displayLanguage;
			draft.pageMode = action.payload.pageMode;
		});
	case uiConstants.APP_CHANGE_LANG:
		return produce(state, draft => {
			draft.lang = action.payload.displayLanguage;
			draft.translate = getTranslation(action.payload.displayLanguage);
		});  
	case uiConstants.APP_BACK_PAGE:
		return produce(state, draft => {
			draft.backPage = action.payload.backPage;
		}); 
	default:
		return state;
  }
}
