import React, { useEffect } from 'react';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { Stack, getTheme, mergeStyleSets, MessageBar, MessageBarType, IIconProps } from'@fluentui/react';
import { appActions } from '../../store/actions/app/actions';
import { NotificationStatus } from '../../store/actions/app/actionTypes';

const theme = getTheme();

const NotificationBar = () => {

  const appState = useSelector((state: RootStateOrAny) => state.appReducer);
  const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
  const dispatch = useDispatch();

  const classes = mergeStyleSets({
    root: {
      paddingTop: 10,
      marginLeft: 0,
      marginRight: 0
    },
    error: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      textAlign: 'left',
      color: 'white',
      borderRadius: '4px',
      background: '#D01C23'
    },
    success: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      textAlign: 'left',
      color: 'white',
      borderRadius: '4px',
      background: '#4CAF50'
    },
    warning: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      textAlign: 'left',
      color: '#201f1e',
      borderRadius: '4px'
    },
    iconError: {
      fontFamily: 'FabricMDL2Icons-3',
      color: 'white',
    }, 
    dismissIconError: {
      fontFamily: 'FabricMDL2Icons',
      color: 'white !important',
    }, 
    iconWarning: {
      fontFamily: 'FabricMDL2Icons-3',
      color: '#201f1e',
    }, 
    dismissIconWarning: {
      fontFamily: 'FabricMDL2Icons',
      color: '#201f1e !important',
    },   
    iconSuccess: {
      fontFamily: 'FabricMDL2Icons-3',
      color: 'white',
    }, 
    dismissIconSuccess: {
      fontFamily: 'FabricMDL2Icons',
      color: 'white !important',
    },                   
  })
  
  const errorIcon: IIconProps = {
    iconName: 'ErrorBadge',
    styles: {
      root: classes.iconError
    }
  };

  const dismissErrorIcon: IIconProps = {
    iconName: 'Clear',
    styles: {
      root: classes.dismissIconError
    }
  };

  const warningIcon: IIconProps = {
    iconName: 'ErrorBadge',
    styles: {
      root: classes.iconWarning
    }
  };

  const dismissWarningIcon: IIconProps = {
    iconName: 'Clear',
    styles: {
      root: classes.dismissIconWarning
    }
  };

  const successIcon: IIconProps = {
    iconName: 'ErrorBadge',
    styles: {
      root: classes.iconSuccess
    }
  };

  const dismissSuccessIcon: IIconProps = {
    iconName: 'Clear',
    styles: {
      root: classes.dismissIconSuccess
    }
  };

  useEffect(() => {
    console.log('appState.notificationStatus ', JSON.stringify(appState.notificationStatus));

  }, [appState.notificationStatus])

  const clearNotification = () => {
    dispatch(appActions.clearNotification());
  };

  const getErrorBar = () => (
    <Stack horizontalAlign='stretch' className={classes.root}>
      <MessageBar messageBarType={MessageBarType.error} messageBarIconProps={errorIcon} dismissIconProps={dismissErrorIcon} className={classes.error} isMultiline={true} onDismiss={clearNotification} dismissButtonAriaLabel="Close">
        {appState.notificationStatus.message}
      </MessageBar>
    </Stack>
  );

  const getWarningBar = () => (
    <div className={classes.root}>
      <MessageBar messageBarType={MessageBarType.warning} messageBarIconProps={warningIcon} dismissIconProps={dismissWarningIcon} className={classes.warning} isMultiline={true} onDismiss={clearNotification} dismissButtonAriaLabel="Close">
        {appState.notificationStatus.message}
      </MessageBar>
    </div>
  );

  const getSuccessBar = () => (
    <div className={classes.root}>
      <MessageBar messageBarType={MessageBarType.success} messageBarIconProps={successIcon} dismissIconProps={dismissSuccessIcon} className={classes.success} isMultiline={true} onDismiss={clearNotification} dismissButtonAriaLabel="Close">
        { appState.notificationStatus.message }
      </MessageBar>
    </div>
  );

  return (
    (appState.notificationStatus !== null && appState.notificationStatus !== undefined) ?
      ((appState.notificationStatus !== null && appState.notificationStatus !== undefined && appState.notificationStatus.status === NotificationStatus.Error) ? 
        getErrorBar() : 
          (appState.notificationStatus !== null && appState.notificationStatus !== undefined && appState.notificationStatus.status === NotificationStatus.Success) ? 
            getSuccessBar() : 
            (appState.notificationStatus !== null && appState.notificationStatus !== undefined && appState.notificationStatus.status === NotificationStatus.Warning) ?
              getWarningBar() : <></> )
      : <></>
  )
}

export default NotificationBar;

