/**
 * Component displays the server files values
 *
 */

 import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
 import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
 import PropTypes from 'prop-types'
 import { Stack, Text, Spinner, SpinnerSize, Image, IImageProps, TextField, Dialog, Checkbox, 
  PrimaryButton, DialogFooter, DialogContent, DialogType, SearchBox, FontIcon, Overlay,ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
 import { appStyles, Utilities} from '../../helpers';
 import { map, cloneDeep, filter, findIndex } from 'lodash'
 import { appActions } from '../../store/actions/app/actions';
 import { File } from '../common'
 import { appConstants } from '../../store/actions/app/actionTypes';
 import { NewFolderDialog } from '../dialogs';
 import { webdavApi } from 'nextcloud-api'
 
 const ServerFilesDialog = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const appState = useSelector((state: RootStateOrAny) => state.appReducer);
    const authentication = useSelector((state: RootStateOrAny) => state.authentication);

    const defaultSearchInterval = 1000;

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [newFolderDialogHidden, setNewFolderDialogHidden ] = useState(true);
    const [timerId, setTimerId] = useState(null);
    const [mask, setMask] = useState('');
    const [pathToSelected, setPathToSelected] = useState([{item: null, name: uiState.translate('home'), path: ""}]);
    const dispatch = useDispatch();

    const pathImage: IImageProps = {
      src: 'assets/breadcrumb.png',
      width: "15px"
    };

    useEffect(() => {
      if (timerId !== null)
        clearTimeout(timerId);

      let timer = setTimeout(function run() {
        checkerThread(mask);
      }, mask !== '' ? defaultSearchInterval : 100);
      setTimerId(timer);
    }, [mask])

    const checkerThread = async (mask: string) => {
      console.log('checkerThread', mask);
      try {
        startSearch(mask)
      }
      catch (ex) {
      }
    }

    const onChangeCheckbox = (file, checked): void => {
      let selectedFilesCopy = [];
      if (checked) {
        selectedFilesCopy.push(file)
      }
      setSelectedFiles(selectedFilesCopy)
    };

    const objectChecked = (object) => {
      return filter(selectedFiles, item => item.name == object.name && item.parentPath == object.parentPath).length > 0
    };

    const onChangeFolder = (item, folderPath, folderName): void => {
      if (appState.searchResult.isSearch) {
        return;
      }

      dispatch({ type: appConstants.APP_SERVER_FILES, payload: { serverFiles: null } });
      console.log('folderPath', folderPath);
      dispatch(appActions.getServerFiles(folderPath));
      const pathToSelectedCopy = cloneDeep(pathToSelected)
      pathToSelectedCopy.push({item, name: folderName, path: folderPath})
      setPathToSelected(pathToSelectedCopy)
      if (props.renderFiles === false)
        setSelectedFiles([item]);
    };

    const onChangePath = (folderPath): void => {
      if (appState.searchResult.isSearch) {
        dispatch({ type: appConstants.APP_SEARCH_RESULT, payload: { isSearch: false } });
        setMask('');
        return;
      }

      dispatch({ type: appConstants.APP_SERVER_FILES, payload: { serverFiles: null } });
      console.log('folderPath', folderPath);
      dispatch(appActions.getServerFiles(folderPath));
      const pathToSelectedNew = []
      pathToSelectedNew.push(pathToSelected[0])
      let i = 0;
      while (i < pathToSelected.length)
      {
        if (pathToSelected[i].path !== folderPath) {
          pathToSelectedNew.push(pathToSelected[i + 1]);
          i += 1;
        }
        else
          break
      }
      setPathToSelected(pathToSelectedNew)
      if (props.renderFiles === false) {
        const item = pathToSelectedNew[pathToSelectedNew.length - 1];
        if (item.item !== null && item.item !== undefined)
          setSelectedFiles([item.item]);
        else
          setSelectedFiles([]);
      }
    };


    const renderFolders = () => { 
      const serverFiles = appState.searchResult.isSearch ? appState.searchResult.serverFiles : appState.serverFiles;
      const folders = filter(serverFiles, item => item.type === 'directory')
      if (!appState.searchResult.isSearch)
        folders.splice(0, 1)
      return <Stack tokens={{ childrenGap: 5 }}>
      {serverFiles !== null ?
        map(folders, (item, index) => {
            if (props.renderFiles) {
              return <Stack key={index} style={{cursor: appState.searchResult.isSearch ? 'default' : 'pointer'}} onClick={(e: React.FormEvent<HTMLElement>): void => onChangeFolder(item, item.path, item.name)} tokens={{ childrenGap: 10 }}> 
                <File item={item} displayPath={appState.searchResult.isSearch ? true : false}></File>
              </Stack>
              }
            else {
              const checked = selectedFiles[0] === item;
              return <Stack key={index}  style={{cursor: appState.searchResult.isSearch ? 'default' : 'pointer'}} horizontal  tokens={{ childrenGap: 5 }}> 
                <Stack verticalAlign="center"> 
                  <Checkbox styles={checked ? appStyles.checkBoxStyle : {}} onChange={(e: React.FormEvent<HTMLElement>, checked: boolean): void => onChangeCheckbox(item, checked)} 
                  checked={checked}/>
                </Stack>
                <Stack onClick={(e: React.FormEvent<HTMLElement>): void => onChangeFolder(item, item.path, item.name)}> 
                  <File item={item} displayPath={appState.searchResult.isSearch ? true : false}></File>
                </Stack>
              </Stack>
            }
        })
        :<></>
      }
      </Stack>     
    };

    const renderFiles = () => { 
      const serverFiles = appState.searchResult.isSearch ? appState.searchResult.serverFiles : appState.serverFiles;
      const files = filter(serverFiles, item => item.type !== 'directory')
      return <Stack tokens={{ childrenGap: 5 }}>
      {serverFiles !== null?
        map(files, (item, index) => {
          if (props.renderFiles) {
            const selected = filter(appState.filesToUpload, value => value.name == item.name).length > 0;
            const checked = objectChecked(item);
            return <Stack key={index} horizontal tokens={{ childrenGap: 5 }}> 
                <Stack verticalAlign="center"> 
                  <Checkbox styles={!selected && checked ? appStyles.checkBoxStyle : {} } onChange={(e: React.FormEvent<HTMLElement>, checked: boolean): void => onChangeCheckbox(item, checked)} checked={checked}
                  disabled={selected}/>
                </Stack>
                <File item={item} displayPath={appState.searchResult.isSearch ? true : false}></File>
            </Stack>
          }
          else {
            return <Stack key={index}> 
                    <File item={item} displayPath={appState.searchResult.isSearch ? true : false}></File>
                </Stack>
          }
        })
        :<></>
      }
      </Stack>     
    };

    const renderPath = () => { 
      return <Stack horizontal tokens={{ childrenGap: 2 }}>
      {pathToSelected.length !== 0?
        map(pathToSelected, (item, index) => {
            return <Stack horizontal verticalAlign="center"  key={item.name} > 
                <Text className={appStyles.globalStyles.label} style={{cursor: 'pointer'}} onClick={(e: React.FormEvent<HTMLElement>): void => onChangePath(item.path)}> {item.name}</Text>
                <Stack verticalAlign="center"> 
                  <Image {...pathImage} /> 
                </Stack>
            </Stack>
        })
        :<></>
      }
      </Stack>     
    };
    

    const dialogContentProps = {
      type: DialogType.close
    };
    
    const hideDialog = async (e) => {
      e.stopPropagation();
      props.onDismiss(true);
      setPathToSelected([{item: null, name: uiState.translate('home'), path: ""}])
      dispatch({ type: appConstants.APP_SERVER_FILES, payload: { serverFiles: null } });
      dispatch({ type: appConstants.APP_SEARCH_RESULT, payload: { isSearch: false } });
      setSelectedFiles([])
    }

    const selectFiles = () => {
      props.onDismiss(true);
      if (props.renderFiles)
        dispatch({ type: appConstants.APP_FILES_TO_UPLOAD, payload: { filesToUpload: selectedFiles } });
      else if (selectedFiles.length > 0)
        dispatch({ type: appConstants.APP_DESTINATION_FOLDER, payload: { destinationFolder: selectedFiles[0] } });
      dispatch({ type: appConstants.APP_SERVER_FILES, payload: { serverFiles: null } });
      dispatch({ type: appConstants.APP_SEARCH_RESULT, payload: { isSearch: false } });
      setPathToSelected([{item: null, name: uiState.translate('home'), path: ""}])
      setSelectedFiles([])
    }

    const newFolder = () => {
      setNewFolderDialogHidden(false);
    }

    const createFolder = (folder: string) => {
      //onChangePath(pathToSelected[pathToSelected.length - 1].path);
      dispatch({ type: appConstants.APP_SERVER_FILES, payload: { serverFiles: null } });
      if (props.renderFiles === false && selectedFiles.length > 0)
        setSelectedFiles([])
      dispatch(appActions.createFolder(pathToSelected[pathToSelected.length - 1].path, folder));
    }

    const checkFolderExist = async (folder: string) => {
      let exists = await webdavApi.checkFolderExist(appState.appConfig.stubUrl, authentication.user, 
        authentication.userConfig.userInfo, pathToSelected[pathToSelected.length - 1].path + '/' + folder);
      if (exists.success === true) {
        return exists.result;
      }

      return false;
    }

    const startSearch = (mask: string) => {
      if (mask !== '') {
        if (props.renderFiles === false && selectedFiles.length > 0)
          setSelectedFiles([]);
        dispatch({ type: appConstants.APP_SEARCH_RESULT, payload: { isSearch: true } });
        dispatch(appActions.searchServerFiles(pathToSelected[pathToSelected.length - 1].path, mask));
      }
      else {
        if (appState.searchResult.isSearch) {
          if (props.renderFiles === false && selectedFiles.length > 0)
            setSelectedFiles([]);
          dispatch({ type: appConstants.APP_SEARCH_RESULT, payload: { isSearch: false } });
        }
      }
    }

    return (
    <Stack > 
      <Dialog
          styles={appStyles.DialogStyle}
          hidden={props.hidden}
          onDismiss={hideDialog}
          dialogContentProps={dialogContentProps}>
        <Stack tokens={{ childrenGap: 10 }} style={{ position: 'relative', width: '100%', height: 420}}>
          {renderPath()}
          <Stack style={{ position: 'relative', width: '100%'}}>
            <SearchBox 
              autoComplete="off"
              onChange={(_, newValue) => {setMask(newValue)}}
              onClear={ev => {setMask('')}}
              placeholder={uiState.translate('share_search')}
            />
            { appState.searchResult.isSearch && ! appState.searchResult.isSearchComplete ? 
            <Stack style={{ position: 'absolute', top: 5, right: 30 }} >
                <Spinner size={SpinnerSize.medium} style={{  }} />
            </Stack>
            : <></>
            }
          </Stack>
           { Utilities.isNANObject(appState.serverFiles) ?
            <Stack className={appStyles.globalStyles.filesContent} verticalAlign="center" horizontalAlign="center">
                <Spinner size={SpinnerSize.large} style={{ marginTop: 170 }} />
            </Stack>:
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} className={appStyles.globalStyles.filesContent}> 
              <Stack tokens={{ childrenGap: 10 }} style={{ margin: 5 }}>
                {renderFolders()}
                {renderFiles()}
              </Stack>
            </ScrollablePane>
          }
          <DialogFooter styles={{ actions: { position: 'absolute', bottom: 0, height: 40, margin: 0, lineHeight: 0 } }}>
            <Stack horizontal horizontalAlign={appState.searchResult.isSearch ? 'end' : 'space-between'} style={{ marginTop: 5 }}>
              {!appState.searchResult.isSearch ?
              <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('button_newfolder') }  onClick={newFolder} />
              : <></>
              }
              <PrimaryButton styles={appStyles.primaryButton} text={uiState.translate('select') } onClick={selectFiles} />
            </Stack>
          </DialogFooter>
        </Stack>
      </Dialog>  
      <NewFolderDialog 
            hidden={newFolderDialogHidden}
            onDismiss={setNewFolderDialogHidden}
            createFolder={createFolder}
            checkFolderExist={checkFolderExist} />
    </Stack>
   )
 }
 
 export default ServerFilesDialog;
 
 ServerFilesDialog.propTypes = {
    hidden: PropTypes.bool,
    onDismiss: PropTypes.func, 
    renderFiles: PropTypes.bool
 }