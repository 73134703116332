 import React, { useState, useEffect, useRef, ChangeEvent } from 'react';
 import PropTypes from 'prop-types'
 import { Stack, Text, FontIcon } from '@fluentui/react';
 import { appStyles, Utilities } from '../../helpers';
 
 const WizardOption = (props) => {

    return (
    <Stack horizontal tokens={{ childrenGap: 5 }}>
      {Utilities.isNANObject(props.displayIcon) || props.displayIcon === true?
        <Stack verticalAlign='center' horizontalAlign='start' style={{ width: 30}} > 
        {props.value !== '' ?
          <FontIcon iconName="Completed" className={appStyles.globalStyles.optionIconSet} />
        :
          <FontIcon iconName="Info" className={appStyles.globalStyles.optionIconNotSet} />
        }
        </Stack>
        : <></>
      }
      <Text className={appStyles.globalStyles.text}> {props.value !== '' ? props.value : props.notSelectedText }</Text>
    </Stack>
   )
 }
 
 export default WizardOption;
 
 WizardOption.propTypes = {
    value: PropTypes.string,
    notSelectedText: PropTypes.string,
    displayIcon: PropTypes.bool
 }