/**
 * Component displays the button with predefined (onstants) values
 *
 */

 import React from 'react';
 import PropTypes from 'prop-types'
 import { Stack, Text, Image, IImageProps } from '@fluentui/react';
 import { appStyles} from '../../helpers';
 import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
 
 const OptionsButton = (props) => {
    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);

    const ControlImage: IImageProps = {
        src: props.img,
        width: "100%",
        height: "100%"
    }

    const onClick = () => {
        props.onClick(false)
      }

   return (
    <Stack horizontal className={appStyles.globalStyles.buttonStack} tokens={{ childrenGap: 10 }} onClick={onClick} >  
        <Stack verticalAlign='center' className={appStyles.globalStyles.buttonImageStack} > 
            <svg dangerouslySetInnerHTML={{ __html: props.img }} />
        </Stack>
        <Stack verticalAlign='baseline' style={{paddingTop: 10, paddingBottom: 10}}>    
            <Text className={appStyles.globalStyles.buttonLabel}> {props.title}</Text>
            <Text className={appStyles.globalStyles.buttonComment}> {props.value !== '' ? props.value : uiState.translate('share_not_set')}</Text>
        </Stack>
    </Stack>
   )
 }
 
 export default OptionsButton;
 
 OptionsButton.propTypes = {
   title: PropTypes.string,
   value: PropTypes.string,
   img: PropTypes.string,
   onClick: PropTypes.func
 }