import React, { useState } from 'react';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import PropTypes from 'prop-types';
import Tab from './tab';
import { history } from '../../helpers';
import { Stack, FontIcon, Label } from '@fluentui/react';
import { appStyles } from '../../helpers';
import { initializeIcons } from '../../icons';

initializeIcons('/assets/icons/fonts/');

const Tabs = (props) => {

    const uiState = useSelector((state: RootStateOrAny) => state.uiReducer);
    const appState = useSelector((state: RootStateOrAny) => state.appReducer);
    const authentication = useSelector((state: RootStateOrAny) => state.authentication);
    const dispatch = useDispatch();

    const onTabActivated  = (tabId) => {
        history.replace('/' + tabId);
    }

    const isRead = () => {
        if (Office.context.mailbox.item.displayReplyForm != undefined) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <Stack horizontal horizontalAlign="space-between" className={appStyles.globalStyles.tabs}>
            <Stack horizontal>
                { (uiState.pageMode === 'file') ?
                <Tab id="file" activeTab={ props.activeTab } label={uiState.translate('tabs_sharefiles')} iconName="CloudUpload" onClick={onTabActivated} />
                : <></>
                }
                { (uiState.pageMode === 'link') ?
                <Tab id="link" activeTab={ props.activeTab } label={uiState.translate('tabs_sharelink')} iconName="Link" onClick={onTabActivated} />
                : <></>
                }
            </Stack>
            <Stack horizontal horizontalAlign="center">
                <Tab id="settings" activeTab={ props.activeTab } label={uiState.translate('tabs_settings')} iconName="Settings" onClick={onTabActivated} />
            </Stack>
       </Stack>
    )
}

export default Tabs;

Tabs.propTypes = {
    activeTab: PropTypes.string.isRequired,
};

