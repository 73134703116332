import { combineReducers } from 'redux';
import { appReducer } from './app/reducer';
import { uiReducer } from './ui/reducer';
import { authentication } from './auth/reducer';

const rootReducer = combineReducers({
  appReducer,
  uiReducer,
  authentication
});

export default rootReducer;
