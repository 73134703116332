/**
 * Component displays the UI for Actual/Plan years selections
 *
 */

 import React, { useState, useEffect }  from 'react';
 import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
 import PropTypes from 'prop-types'
 import { Utilities } from '../../helpers';
 import { Stack, Text, DatePicker, DayOfWeek, IDatePickerStrings, mergeStyleSets} from '@fluentui/react';
 import { toNumber } from 'lodash'
 
 const DateTime = (props) => {
 
  const authentication = useSelector((state: RootStateOrAny) => state.authentication);

  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Monday);

  const minDate = new Date()
  minDate.setDate(minDate.getDate() + 1)

  const DayPickerStrings: IDatePickerStrings = {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
  
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
  
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
  
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',
    monthPickerHeaderAriaLabel: '{0}, select to change the year',
    yearPickerHeaderAriaLabel: '{0}, select to change the month',
  };
  
  const controlClass = mergeStyleSets({
    control: {
      margin: '0 0 15px 0',
      width: '100%',
    },
  });


  useEffect(() => {
  }, []);

  const onSelectDate = (date: Date | null | undefined): void => {
    if (props.onDateChanged !== null && props.onDateChanged !== undefined) {
      const dt = new Date(date);
      props.onDateChanged(dt);
    }
  };

  const getMaxDate = () => {
    if (authentication.filesSharingSettings.ExpireDateEnabled === true &&
      authentication.filesSharingSettings.ExpireDateEnforced === true) {
      const nowDate = new Date();
      if (!Utilities.isNANObject(authentication.filesSharingSettings.ExpireDateDays) && 
        toNumber(authentication.filesSharingSettings.ExpireDateDays) > 0)
        nowDate.setDate(nowDate.getDate() + toNumber(authentication.filesSharingSettings.ExpireDateDays));
      else
        nowDate.setDate(nowDate.getDate() + 1);
      return nowDate;
    }
    return null;
  };



  return (<Stack horizontal verticalAlign='start' aria-disabled={props.disabledContent} tokens={{ childrenGap: 5 }}>
      <DatePicker disabled={props.disabled}
          className={controlClass.control}
          firstDayOfWeek={firstDayOfWeek}
          strings={DayPickerStrings}
          formatDate={Utilities.formatLocaleDate}
          showMonthPickerAsOverlay={true}
          value={props.date}
          onSelectDate={onSelectDate}
          minDate={ minDate }
          maxDate={ getMaxDate() } 
      />
  
  </Stack>)
}

export default DateTime;

DateTime.propTypes = {
  date: PropTypes.any,
  onDateChanged: PropTypes.func
}

 
 