/**
 * Contains helper functions to access local/session storages
 */
import { Utilities } from './utils';
import { Logger } from './logger';

export const storeKeys = {
    LOGGED_USER: 'sendent_teams_logged_user',
    WELCOME_SKIP_NEXT: 'sendent_teams_welcome_skip',
    LANG: 'sendent_teams_lang',
    SERVER_URL: 'sendent_teams_server_url',
    CACHE_USER_CONFIGURATION: 'sendent_teams_user_configuration'
}

/**
 * Check is the localstorage contains the object by key
 * @param key 
 */
const storeContainsKey = (key: string): boolean => {
    try {
        const item = localStorage.getItem(key);
        return (item !== null && item !== undefined);
    } catch(e) {
        Logger.info(`storeContainsKey exception: ${e.message}`);
    }
	return false;
}

/** 
 * Gets the object from the localstorage by key
 * @param key 
*/
const storeGetObject = (key: string): string => {
    try {
        const item = localStorage.getItem(key);
        return (item === null || item === undefined) ? null : item;
    } catch(e) {
        Logger.info(`storeGetObject exception: ${e.message}`);
    }
	return null;
}

/**
 * Saves the object in localStorage
 * @param user 
 * @param keepSigned 
 */
const storeSetObject = (key: string, item: string) => {
    try {
        localStorage.setItem(key, item);
    } catch(e) {
        Logger.info(`storeSetObject exception: ${e.message}`);
    }
}

/**
 * Removes the object from localstorage
 * @param key 
 */
const storeRemoveKey = (key: string) => {
    try {
        localStorage.removeItem(key);
    } catch(e) {
        Logger.info(`storeRemoveKey exception: ${e.message}`);
    }
}

/**
 * Checks is Local storage available
 */
const isLocalStorageSupported = (): boolean => {
    try {
        localStorage.getItem("test");
        return true;
    } catch(e) {
        Logger.info(`isLocalStorageSupported exception: ${e.message}`);
    }
	return false;
}

/**
 * Returns the locatstorage object as boolean
 * @param key 
 * @param defValue 
 */
const storeGetBooleanObject = (key: string, defValue: boolean): boolean => {
    if (storeContainsKey(key)) {
        const storeVal = '' + storeGetObject(key);
        return storeVal === 'false' ? false : true;
    }
	return defValue;
}

/** 
 * Checks the logged user
*/
function storeHasUser(): boolean {
    let user = localStorage.getItem(storeKeys.LOGGED_USER);
    if (user === null || user === undefined)
        user = sessionStorage.getItem(storeKeys.LOGGED_USER);
    if (user !== null && user !== undefined ) {
        const obj = JSON.parse(user);
        if (Utilities.isNANObject(obj.LoginName) || Utilities.isNANObject(obj.server))
            user = null;  
    }        
    return (user !== null && user !== undefined);
}

/** 
 * Gets the logged user from the localstorage or sessionstorage
*/
function storeGetUser() {
    let user = localStorage.getItem(storeKeys.LOGGED_USER);
    if (user === null || user === undefined)
        user = sessionStorage.getItem(storeKeys.LOGGED_USER);
    if (user !== null && user !== undefined ) {
        const obj = JSON.parse(user);
        if (Utilities.isNANObject(obj.loginName) || Utilities.isNANObject(obj.server))
            user = null;     
    }
    return (user === null || user === undefined) ? null : JSON.parse(user);
}

/**
 * Saves the logged user in session and in localStorage (depends on the keepSigned flag)
 * @param user 
 * @param keepSigned 
 */
function storeSetUser(user, keepSigned: boolean): void {
    try {
        sessionStorage.setItem(storeKeys.LOGGED_USER, JSON.stringify(user));
        if (keepSigned) {
            localStorage.setItem(storeKeys.LOGGED_USER, JSON.stringify(user));    
        }
    } catch(e) {}
}

function storeClearUser(): void {
    try {
        localStorage.removeItem(storeKeys.LOGGED_USER);
        sessionStorage.removeItem(storeKeys.LOGGED_USER);
    } catch(e) {}
    
}

const storeSessionKeys = {
}


/**
 * Check is the localstorage contains the object by key
 * @param key 
 */
 const storeSessionContainsKey = (key: string): boolean => {
    try {
        const item = sessionStorage.getItem(key);
        return (item !== null && item !== undefined);
    } catch(e) {
        Logger.info(`storeSessionContainsKey exception: ${e.message}`);
    }
	return false;
}

/** 
 * Gets the object from the localstorage by key
 * @param key 
*/
const storeSessionGetObject = (key: string): string => {
    try {
        const item = sessionStorage.getItem(key);
        return (item === null || item === undefined) ? null : item;
    } catch(e) {
        Logger.info(`storeSessionGetObject exception: ${e.message}`);
    }
	return null;
}

/**
 * Saves the object in localStorage
 * @param user 
 * @param keepSigned 
 */
const storeSessionSetObject = (key: string, item: string) => {
    try {
        sessionStorage.setItem(key, item);
    } catch(e) {
        Logger.info(`storeSessionSetObject exception: ${e.message}`);
    }
}

/**
 * Removes the object from localstorage
 * @param key 
 */
const storeSessionRemoveKey = (key: string) => {
    try {
        sessionStorage.removeItem(key);
    } catch(e) {
        Logger.info(`storeSessionRemoveKey exception: ${e.message}`);
    }
}

/**
 * Checks is Local storage available
 */
const isSessionStorageSupported = (): boolean => {
    try {
        sessionStorage.getItem("test");
        return true;
    } catch(e) {
        Logger.info(`isSessionStorageSupported exception: ${e.message}`);
    }
	return false;
}

/**
 * Returns the locatstorage object as boolean
 * @param key 
 * @param defValue 
 */
const storeSessionGetBooleanObject = (key: string, defValue: boolean): boolean => {
    if (storeSessionContainsKey(key)) {
        const storeVal = '' + storeSessionGetObject(key);
        return storeVal === 'false' ? false : true;
    }
	return defValue;
}

export const LocalStorageHelper = {
    storeKeys,
	storeContainsKey,
	storeGetObject,
	storeSetObject,
	storeRemoveKey,
	isLocalStorageSupported,
	storeGetBooleanObject
}

export const SessionStorageHelper = {
    storeSessionKeys,
	storeSessionContainsKey,
	storeSessionGetObject,
	storeSessionSetObject,
	storeSessionRemoveKey,
	isSessionStorageSupported,
	storeSessionGetBooleanObject
}

export const UserStorageHelper = {
    storeHasUser,
	storeGetUser,
	storeSetUser,
	storeClearUser
}

