import { store } from '../store';
import { mergeStyleSets , IIconProps } from '@fluentui/react';
import { WhiteBoardApp16Icon } from '@fluentui/react-icons';
import { IButtonStyles } from '@fluentui/react';
import { Utilities } from '.';
 
const isTheme = (state: any): boolean => {
  if (!Utilities.isNANObject(state) && 
    !Utilities.isNANObject(state.authentication) && 
    !Utilities.isNANObject(state.authentication.theme) &&
    state.authentication.theme.AdvancedThemingEnabled)
    return true
  else 
    return false;
}

const getSettingsTheme = (state: any) => {
  const theme = isTheme(state);

  return {
    settingsPanel: {
      borderTop: 'solid 1px #c8c6c4',
      cursor: 'pointer',
      paddingTop: 6
    },
    settingsText: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#323130',
    },
    settingsIconContainer: {
      width: '20px',
      height: '16px',
      borderLeft: 'solid 2px #c8c6c4',
      paddingLeft: 8
    },
    settingsIcon: {
      color: '#605e5c',
      fontSize: 12,
    },
    settingsHeaderLayout: {
      backgroundColor: theme ? state.authentication.theme.DialogHeaderColor : 'transparent',
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: 5,
      paddingRight: 5,
      marginTop: 20,
      marginBottom: 15
    },
    settingsTitle: {
      fontSize: 21,
      fontStyle: 'normal',
      fontWeight: '600',
      color: theme ? state.authentication.theme.DialogHeaderFontColor : '#000000',
    },
    settingsSectionTitle: {
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: '600',
      color: '#000000',
    },
    settingsBack: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#0581e3',
    },
    settingsLicenseLabel: {
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#000000',
    },
    settingsLicenseText: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#000000',
    },
  };
}

const getTabsTheme = (state: any) => {
  const theme = isTheme(state);

  return {
    tabs: {
      display: "flex",
      backgroundColor: "white",
      padding: "0px",
      margin: "0px",
      borderBottom: "1px solid #f8f8f8",
      marginLeft: "5px",
      marginTop: "5px"
    },
    tabsItem: {
      marginBottom: "0px",
      padding: "10px 10px 0px 10px",
      cursor: "pointer",
      flex: "1",
      color: "#333",
      fontSize: "12px"
    },
    tabsItemActive: {
      marginBottom: "0px",
      padding: "10px 10px 0px 10px",
      cursor: "pointer",
      flex: "1",
      color: "#333",
      fontSize: "12px",
      border: "solid #f36928",
      borderWidth: "0px 0px 2px 0px"
    },   
    tabsLabel: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#333',
      cursor: 'pointer',
      whiteSpace: 'nowrap'
    },
  };
}

const globalStyles = () => {
  const state = store.getState();
  const theme = isTheme(state);

  return mergeStyleSets({
    pageHeaderLayout: {
      backgroundColor: theme ? state.authentication.theme.DialogHeaderColor : 'trasparent',
      paddingBottom: 5,
      paddingTop: 5,
      paddingLeft: theme ? 5 : 0,
      paddingRight: theme ? 5 : 0,
      marginTop: 20,
      marginBottom: 15,
      textAlign: 'center'
    },
    pageHeader: {
      fontSize: 21,
      color: theme ? state.authentication.theme.DialogHeaderFontColor : '#000000',
      fontStyle: 'normal',
      fontWeight: '600',
    },
    text: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },  
    label: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: '600',
      textAlign: 'left',
      color: '#323130',
    },
    part: {
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: '600',
    },
    comment: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    file: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    date: {
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    errorLoginStack: {
      paddingLeft: 12,
      paddingTop: 12,
      paddingRight: 12,
      height: '78px',
      color: '#ffffff',
      background: '#f36928'
    },
    buttonStack: {
      paddingLeft: 10,
      paddingRight: 10,
      height: '48px',
      width: "100%",
      cursor: 'pointer',
      background: theme ? state.authentication.theme.DialogFooterBackgroundColor : '#0078d4', 
      selectors: {
        ':hover': {
          backgroundColor: theme ? state.authentication.theme.DialogFooterHoverColor : '#106ebe',
        }
      }
    },
    buttonImageStack: {
      width: 38,
      height: 38,
      margin: 5,
      padding: 0,
      backgroundColor: theme ? state.authentication.theme.DialogFooterIconBackgroundColor : '#FFFFFF'
    },
    buttonLabel: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: '600',
      color: theme ? state.authentication.theme.DialogFooterFontColor : '#FFFFFF'  
    },
    buttonComment: {
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: theme ? state.authentication.theme.DialogFooterFontColor : '#FFFFFF'
    },  
    sectionLayout: {
      paddingTop: 20
    },
    sectionLayout10: {
      paddingTop: 10
    },
    sectionLayout20: {
      paddingTop: 20
    },
    sectionLayout30: {
      paddingTop: 30
    },
    sectionLayout50: {
      paddingTop: 50
    },
    sectionLayoutBorder: {
      marginTop: '30px !important',
      paddingTop: '15px',
      borderTop: '1px solid #98908c'
    },
    filesContent: {
      position: 'absolute',
      top: 60,
      bottom: 45,
      left: 0,
      right: 0,
      border: '1px solid #98908c',
      display: 'inline-block', 
      overflow: 'auto', 
      zIndex: 100
    },
    optionIconNotSet: {
      fontSize: 20,
      color: '#323232'
    },
    optionIconSet: {
      fontSize: 20,
      color: 'green'
    },
    wizardContent: {
      minHeight: '110px'
    },
    scrollWizard: {
      marginTop: "63px !important",
      marginBottom: "70px !important"
    },
    scrollWizardNoConfirm: {
      marginTop: "63px !important",
      marginBottom: "0px !important"
    },
    wizardButtons: {
      position: 'absolute',
      bottom: '10px',
      right: '0px',
      width: '100%',
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '0px',
      borderTop: '1px solid #98908c'
    },
    error: {
      textTransform: 'none',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      textAlign: 'left',
      color: 'white',
      borderRadius: '4px',
      background: '#D01C23'
    },
  },
  getSettingsTheme(state),
  getTabsTheme(state))      
}

const textBoxStyle = {
  root: {
    label: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      textAlign: 'left',
      color: '#323130',
    }
  },
};

const dropDownStyle = {
  root: {
    label: {
      fontStyle: 'normal',
      fontWeight: '600',
      fontSize: '14px',
      textAlign: 'left',
      color: '#323130',
    }
  },
};

const choiceStyle = {
  flexContainer: 
    {
      //color: "#ADD8E6",
      /*
      selectors: {
        ".ms-ChoiceField": {
          color: "#00008B",
          //fontWeight: 600
        },
        "input[type='radio']" : {
          width: 15,
          height: 15,
          borderRadius: 15,
          top: -2,
          left: -1,
          //position: "relative",
          backgroundColor: "#d1d3d1",
          content: "",
          display: "inline-block",
          visibility: "visible",
          border: "2px solid white"
        },
        "input[type='radio']:after" : {
          width: 15,
          height: 15,
          borderRadius: 15,
          top: -2,
          left: -1,
          //position: "relative",
          backgroundColor: "#d1d3d1",
          content: "",
          display: "inline-block",
          visibility: "visible",
          border: "2px solid white"
        },
        "input[type='radio']:checked:after" : {
          width: 15,
          height: 15,
          borderRadius: 15,
          top: -2,
          left: -1,
          //position: "relative",
          backgroundColor: "#d1d3d1",
          content: "",
          display: "inline-block",
          visibility: "visible",
          border: "2px solid white"
        }
      }*/
    }
  ,
  root: {
    label: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 'normal',
      textAlign: 'left',
      color: '#323130',
    },
  },
};

const checkBoxStyle = {
  root: {
    selectors: {
      ':hover .ms-Checkbox-label .ms-Checkbox-checkbox': {
        backgroundColor: '#d14d0f',
        borderColor: '#d14d0f'
      },
      ':hover .ms-Checkbox-label .ms-Checkbox-checkmark': {
        backgroundColor: '#d14d0f',
        opacity: '1',
      },
    },
  },
  text: {
    fontSize: 14,
    color: '#858585',
    fontStyle: 'normal',
    fontWeight: 'normal',
  },
  checkmark: {
    color: 'white',
  },
  checkbox: {
    backgroundColor: '#f36928',
    borderColor: '#f36928'
  }
  /*
  checkmark: {
    backgroundColor: '#f36928 !important',
    color: 'white',
    width: '100%',
    height: '100%',
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    verticalAlign: "middle",
    borderRadius: '0px'
  },
  checkbox: {
    backgroundColor: 'white',
    borderColor: 'black',
    borderRadius: '0px',
    backgroundRepeat: "repeat",
    selectors: {
      '::after': {
        backgroundColor: 'red',
      },
    },
  }*/
}

const toggleStyle = {
  root: {
    selectors: {
      ':hover .ms-Toggle-background': {
        backgroundColor: '#d14d0f',
      },
    },
  },
  pill: {
    backgroundColor: '#f36928',
  }
}

const getProtectSvg = (): string => {
  const state = store.getState();
  if (!Utilities.isNANObject(state) && !Utilities.isNANObject(state.authentication) && !Utilities.isNANObject(state.authentication.theme) &&
    state.authentication.theme.AdvancedThemingEnabled) {
      const theme = state.authentication.theme;
      return '<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
        '<title>icon_protect</title>' +
        '<desc>Created with Sketch.</desc>' +
        '<g id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
        '    <g id="icon_protect">' +
        '        <rect id="ModColor_back" fill="transparent" x="0" y="0" width="35" height="35"></rect>' +
        '        <path d="M28.8833339,13.2201409 L29,13.4772727 L7,13.4772727 L7,13 L28.778067,12.9998658 C28.8133025,13.0717675 28.8483915,13.1451925 28.8833339,13.2201409 Z M15.6197479,7 C15.8194499,7 16.0136182,7.0597627 16.1778442,7.17022118 L16.2725915,7.24250722 L19.9995117,10.4545455 L26.0384615,10.4545455 C26.8134523,10.4545455 27.5388984,10.9694235 28.2147997,11.9991795 L7,12 L7,10.0227273 C7,8.42220475 8.24165859,7.09773053 9.78846313,7.00516672 L9.96153846,7 L15.6197479,7 Z" id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '" fill-rule="nonzero"></path>' +
        '        <path d="M29.8083635,13 C29.8665458,13 29.924617,13.0050777 29.9819164,13.0151755 C30.52582,13.1110261 30.8890386,13.6296493 30.793188,14.1735529 L30.793188,14.1735529 L30.0007361,18.6739809 C29.0912936,18.2418215 28.073887,18 27,18 C23.1340068,18 20,21.1340068 20,25 C20,25.695354 20.1013887,26.3670274 20.2901893,27.0010432 L8.00183105,27 L5.2021394,14.213896 C5.18675525,14.1436371 5.17899584,14.0719235 5.17899584,14 C5.17899584,13.4477153 5.62671109,13 6.17899584,13 L6.17899584,13 Z" id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '" fill-rule="nonzero"></path>' +
        '        <path d="M27,20 C29.7614237,20 32,22.2385763 32,25 C32,27.7614237 29.7614237,30 27,30 C24.2385763,30 22,27.7614237 22,25 C22,22.2385763 24.2385763,20 27,20 Z M26.928579,22 C26.1015668,22 25.4285731,22.6729937 25.4285731,23.5 L25.4285731,24.5714387 L25.3214298,24.5714387 C25.1439738,24.5714387 25,24.7154124 25,24.8928685 L25,26.8214475 C25,26.9989036 25.1439738,27.1428773 25.3214298,27.1428773 L28.5357282,27.1428773 C28.7131842,27.1428773 28.857158,26.9989036 28.857158,26.8214475 L28.857158,24.8928685 C28.857158,24.7154124 28.7131842,24.5714387 28.5357282,24.5714387 L26.0714328,24.5714387 L26.0714328,23.5 C26.0714328,23.0279058 26.4564789,22.6428597 26.928579,22.6428597 C27.4006791,22.6428597 27.7857252,23.0279058 27.7857252,23.5 C27.7857252,23.6171938 27.8828238,23.7142924 28.0000118,23.7142924 L28.2142983,23.7142924 C28.3314863,23.7142924 28.4285849,23.6171938 28.4285849,23.5 C28.4285849,22.6729937 27.7555912,22 26.928579,22 Z" id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '"></path>' +
        '    </g>' +
        '</g>' +
      '</svg>';
  }
  else
    return '<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
      '<title>icon_protect</title>' +
      '<desc>Created with Sketch.</desc>' +
      '<g id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
      '    <g id="icon_protect">' +
      '        <rect id="ModColor_back" fill="transparent" x="0" y="0" width="35" height="35"></rect>' +
      '        <path d="M28.8833339,13.2201409 L29,13.4772727 L7,13.4772727 L7,13 L28.778067,12.9998658 C28.8133025,13.0717675 28.8483915,13.1451925 28.8833339,13.2201409 Z M15.6197479,7 C15.8194499,7 16.0136182,7.0597627 16.1778442,7.17022118 L16.2725915,7.24250722 L19.9995117,10.4545455 L26.0384615,10.4545455 C26.8134523,10.4545455 27.5388984,10.9694235 28.2147997,11.9991795 L7,12 L7,10.0227273 C7,8.42220475 8.24165859,7.09773053 9.78846313,7.00516672 L9.96153846,7 L15.6197479,7 Z" id="ModColor_prim" fill="#0078d4" fill-rule="nonzero"></path>' +
      '        <path d="M29.8083635,13 C29.8665458,13 29.924617,13.0050777 29.9819164,13.0151755 C30.52582,13.1110261 30.8890386,13.6296493 30.793188,14.1735529 L30.793188,14.1735529 L30.0007361,18.6739809 C29.0912936,18.2418215 28.073887,18 27,18 C23.1340068,18 20,21.1340068 20,25 C20,25.695354 20.1013887,26.3670274 20.2901893,27.0010432 L8.00183105,27 L5.2021394,14.213896 C5.18675525,14.1436371 5.17899584,14.0719235 5.17899584,14 C5.17899584,13.4477153 5.62671109,13 6.17899584,13 L6.17899584,13 Z" id="ModColor_prim" fill="#0078d4" fill-rule="nonzero"></path>' +
      '        <path d="M27,20 C29.7614237,20 32,22.2385763 32,25 C32,27.7614237 29.7614237,30 27,30 C24.2385763,30 22,27.7614237 22,25 C22,22.2385763 24.2385763,20 27,20 Z M26.928579,22 C26.1015668,22 25.4285731,22.6729937 25.4285731,23.5 L25.4285731,24.5714387 L25.3214298,24.5714387 C25.1439738,24.5714387 25,24.7154124 25,24.8928685 L25,26.8214475 C25,26.9989036 25.1439738,27.1428773 25.3214298,27.1428773 L28.5357282,27.1428773 C28.7131842,27.1428773 28.857158,26.9989036 28.857158,26.8214475 L28.857158,24.8928685 C28.857158,24.7154124 28.7131842,24.5714387 28.5357282,24.5714387 L26.0714328,24.5714387 L26.0714328,23.5 C26.0714328,23.0279058 26.4564789,22.6428597 26.928579,22.6428597 C27.4006791,22.6428597 27.7857252,23.0279058 27.7857252,23.5 C27.7857252,23.6171938 27.8828238,23.7142924 28.0000118,23.7142924 L28.2142983,23.7142924 C28.3314863,23.7142924 28.4285849,23.6171938 28.4285849,23.5 C28.4285849,22.6729937 27.7555912,22 26.928579,22 Z" id="ModColor_prim" fill="#0078d4"></path>' +
      '    </g>' +
      '</g>' +
    '</svg>';
}

const getDateSvg = (): string => {
  const state = store.getState();
  if (!Utilities.isNANObject(state) && !Utilities.isNANObject(state.authentication) && !Utilities.isNANObject(state.authentication.theme) &&
    state.authentication.theme.AdvancedThemingEnabled) {
      const theme = state.authentication.theme;
      return '<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
        '<title>icon_date</title>' +
        '<desc>Created with Sketch.</desc>' +
        '<defs>' +
        '    <path d="M29.621,14.9992695 L29.6221021,18.666746 C28.8122609,18.3393543 27.9271818,18.1590909 27,18.1590909 C23.1340068,18.1590909 20,21.2930977 20,25.1590909 C20,25.7936715 20.0844406,26.4085298 20.2426986,26.9930429 L8,26.9924242 C6.34314575,26.9924242 5,25.6492785 5,23.9924242 L5,14.9992695 L29.621,14.9992695 Z M8.456,8.99926948 L8.45643939,11.6041667 C8.45643939,12.2579128 8.98640536,12.7878788 9.64015152,12.7878788 C10.2530385,12.7878788 10.7571344,12.3220884 10.8177523,11.7251944 L10.8238636,11.6041667 L10.823,8.99926948 L23.797,8.99926948 L23.7973485,11.6041667 C23.7973485,12.2170537 24.2631389,12.7211495 24.8600329,12.7817674 L24.9810606,12.7878788 C25.5939476,12.7878788 26.0980435,12.3220884 26.1586614,11.7251944 L26.1647727,11.6041667 L26.164,8.99926948 L26.6212121,9 C28.2780664,9 29.6212121,10.3431458 29.6212121,12 L29.621,13.9992695 L5,13.9992695 L5,12 C5,10.3431458 6.34314575,9 8,9 L8.456,8.99926948 Z" id="path-1"></path>' +
        '</defs>' +
        '<g id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
        '    <g id="icon_date" transform="translate(0.000000, -1.000000)">' +
        '        <g id="date">' +
        '            <g id="Group" transform="translate(0.000000, 0.840909)">' +
        '                <rect id="ModColor_back" fill="transparent" x="0" y="0.159090909" width="35" height="35"></rect>' +
        '                <mask id="mask-2" fill="transparent">' +
        '                    <use xlink:href="#path-1"></use>' +
        '                </mask>' +
        '                <use id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '" xlink:href="#path-1"></use>' +
        '                <circle id="ModColor_back" fill="#F1F1FF" mask="url(#mask-2)" cx="9.593561" cy="11.5469699" r="1.5"></circle>' +
        '                <circle id="ModColor_back" fill="#F1F1FF" mask="url(#mask-2)" cx="24.9" cy="11.6590909" r="1.5"></circle>' +
        '            </g>' +
        '            <rect id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '" x="24.1071429" y="7" width="1.78571429" height="6.25" rx="0.892857143"></rect>' +
        '            <rect id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '" x="8.75" y="7" width="1.78571429" height="6.25" rx="0.892857143"></rect>' +
        '        </g>' +
        '        <path d="M27,21 C29.7614237,21 32,23.2385763 32,26 C32,28.7614237 29.7614237,31 27,31 C24.2385763,31 22,28.7614237 22,26 C22,23.2385763 24.2385763,21 27,21 Z M27,23 C26.7238576,23 26.5,23.2238576 26.5,23.5 L26.5,23.5 L26.5,26.5 C26.5,26.7761424 26.7238576,27 27,27 C27.0172374,27 27.034271,26.9991277 27.0510591,26.9974249 L27.1,27 L29.1,27 C29.3761424,27 29.6,26.7761424 29.6,26.5 C29.6,26.2238576 29.3761424,26 29.1,26 L29.1,26 L27.5,26 L27.5,23.5 C27.5,23.2545401 27.3231248,23.0503916 27.0898756,23.0080557 Z" id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '"></path>' +
        '    </g>' +
        '</g>' +
      '</svg>';
  }
  else
    return '<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
      '<title>icon_date</title>' +
      '<desc>Created with Sketch.</desc>' +
      '<defs>' +
      '    <path d="M29.621,14.9992695 L29.6221021,18.666746 C28.8122609,18.3393543 27.9271818,18.1590909 27,18.1590909 C23.1340068,18.1590909 20,21.2930977 20,25.1590909 C20,25.7936715 20.0844406,26.4085298 20.2426986,26.9930429 L8,26.9924242 C6.34314575,26.9924242 5,25.6492785 5,23.9924242 L5,14.9992695 L29.621,14.9992695 Z M8.456,8.99926948 L8.45643939,11.6041667 C8.45643939,12.2579128 8.98640536,12.7878788 9.64015152,12.7878788 C10.2530385,12.7878788 10.7571344,12.3220884 10.8177523,11.7251944 L10.8238636,11.6041667 L10.823,8.99926948 L23.797,8.99926948 L23.7973485,11.6041667 C23.7973485,12.2170537 24.2631389,12.7211495 24.8600329,12.7817674 L24.9810606,12.7878788 C25.5939476,12.7878788 26.0980435,12.3220884 26.1586614,11.7251944 L26.1647727,11.6041667 L26.164,8.99926948 L26.6212121,9 C28.2780664,9 29.6212121,10.3431458 29.6212121,12 L29.621,13.9992695 L5,13.9992695 L5,12 C5,10.3431458 6.34314575,9 8,9 L8.456,8.99926948 Z" id="path-1"></path>' +
      '</defs>' +
      '<g id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
      '    <g id="icon_date" transform="translate(0.000000, -1.000000)">' +
      '        <g id="date">' +
      '            <g id="Group" transform="translate(0.000000, 0.840909)">' +
      '                <rect id="ModColor_back" fill="transparent" x="0" y="0.159090909" width="35" height="35"></rect>' +
      '                <mask id="mask-2" fill="transparent">' +
      '                    <use xlink:href="#path-1"></use>' +
      '                </mask>' +
      '                <use id="ModColor_prim" fill="#0078d4" xlink:href="#path-1"></use>' +
      '                <circle id="ModColor_back" fill="#F1F1FF" mask="url(#mask-2)" cx="9.593561" cy="11.5469699" r="1.5"></circle>' +
      '                <circle id="ModColor_back" fill="#F1F1FF" mask="url(#mask-2)" cx="24.9" cy="11.6590909" r="1.5"></circle>' +
      '            </g>' +
      '            <rect id="ModColor_prim" fill="#0078d4" x="24.1071429" y="7" width="1.78571429" height="6.25" rx="0.892857143"></rect>' +
      '            <rect id="ModColor_prim" fill="#0078d4" x="8.75" y="7" width="1.78571429" height="6.25" rx="0.892857143"></rect>' +
      '        </g>' +
      '        <path d="M27,21 C29.7614237,21 32,23.2385763 32,26 C32,28.7614237 29.7614237,31 27,31 C24.2385763,31 22,28.7614237 22,26 C22,23.2385763 24.2385763,21 27,21 Z M27,23 C26.7238576,23 26.5,23.2238576 26.5,23.5 L26.5,23.5 L26.5,26.5 C26.5,26.7761424 26.7238576,27 27,27 C27.0172374,27 27.034271,26.9991277 27.0510591,26.9974249 L27.1,27 L29.1,27 C29.3761424,27 29.6,26.7761424 29.6,26.5 C29.6,26.2238576 29.3761424,26 29.1,26 L29.1,26 L27.5,26 L27.5,23.5 C27.5,23.2545401 27.3231248,23.0503916 27.0898756,23.0080557 Z" id="ModColor_prim" fill="#0078d4"></path>' +
      '    </g>' +
      '</g>' +
    '</svg>';
}

const getShareSvg = (): string => {
  const state = store.getState();
  if (!Utilities.isNANObject(state) && !Utilities.isNANObject(state.authentication) && !Utilities.isNANObject(state.authentication.theme) &&
    state.authentication.theme.AdvancedThemingEnabled) {
      const theme = state.authentication.theme;
      return '<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
        '<title>icon_share</title>' +
        '<desc>Created with Sketch.</desc>' +
        '<g id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
        '    <g id="icon_share">' +
        '        <rect id="ModColor_back" fill="transparent" x="0" y="0" width="35" height="35"></rect>' +
        '        <path d="M15.0929435,7 C15.3325858,7 15.5642597,7.08605829 15.745787,7.24250722 L15.745787,7.24250722 L19.6836588,10.6363536 L26.0404306,10.6363536 C27.7522174,10.6363536 29.1578309,12.0710087 29.1578309,13.818163 L29.1578309,13.818163 L29.1574584,17.8313105 C28.4905071,17.6328547 27.7839855,17.5262868 27.0525735,17.5262868 C22.9831182,17.5262868 19.6841728,20.8252321 19.6841728,24.8946875 C19.6841728,25.6260994 19.7907407,26.3326211 19.9891966,26.9995724 L9.11740031,26.9999449 C7.40561353,26.9999449 6,25.5652897 6,23.8181354 L6,23.8181354 L6,10.1818094 C6,8.43465514 7.40561353,7 9.11740031,7 L9.11740031,7 Z" id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '" fill-rule="nonzero"></path>' +
        '        <path d="M27,20 C29.7614237,20 32,22.2385763 32,25 C32,27.7614237 29.7614237,30 27,30 C24.2385763,30 22,27.7614237 22,25 C22,22.2385763 24.2385763,20 27,20 Z M28.75,22 C28.0585938,22 27.5,22.5585938 27.5,23.25 C27.5,23.2929688 27.5039062,23.3398438 27.5078125,23.3828125 L26.1015625,24.0859375 C25.8789062,23.8789062 25.578125,23.75 25.25,23.75 C24.5585938,23.75 24,24.3085938 24,25 C24,25.6914062 24.5585938,26.25 25.25,26.25 C25.578125,26.25 25.8789062,26.1210938 26.1015625,25.9140625 L27.5078125,26.6171875 C27.5039062,26.6601562 27.5,26.7070312 27.5,26.75 C27.5,27.4414062 28.0585938,28 28.75,28 C29.4414062,28 30,27.4414062 30,26.75 C30,26.0585938 29.4414062,25.5 28.75,25.5 C28.421875,25.5 28.1210938,25.6289062 27.8984375,25.8359375 L26.4921875,25.1328125 C26.4960938,25.0898438 26.5,25.0429688 26.5,25 C26.5,24.9570312 26.4960938,24.9101562 26.4921875,24.8671875 L27.8984375,24.1640625 C28.1210938,24.3710938 28.421875,24.5 28.75,24.5 C29.4414062,24.5 30,23.9414062 30,23.25 C30,22.5585938 29.4414062,22 28.75,22 Z" id="ModColor_prim" fill="' + theme.DialogFooterIconColor + '"></path>' +
        '    </g>' +
        '</g>' +
      '</svg>';
  }
  else
    return '<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">' +
      '<title>icon_share</title>' +
      '<desc>Created with Sketch.</desc>' +
      '<g id="icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">' +
      '    <g id="icon_share">' +
      '        <rect id="ModColor_back" fill="transparent" x="0" y="0" width="35" height="35"></rect>' +
      '        <path d="M15.0929435,7 C15.3325858,7 15.5642597,7.08605829 15.745787,7.24250722 L15.745787,7.24250722 L19.6836588,10.6363536 L26.0404306,10.6363536 C27.7522174,10.6363536 29.1578309,12.0710087 29.1578309,13.818163 L29.1578309,13.818163 L29.1574584,17.8313105 C28.4905071,17.6328547 27.7839855,17.5262868 27.0525735,17.5262868 C22.9831182,17.5262868 19.6841728,20.8252321 19.6841728,24.8946875 C19.6841728,25.6260994 19.7907407,26.3326211 19.9891966,26.9995724 L9.11740031,26.9999449 C7.40561353,26.9999449 6,25.5652897 6,23.8181354 L6,23.8181354 L6,10.1818094 C6,8.43465514 7.40561353,7 9.11740031,7 L9.11740031,7 Z" id="ModColor_prim" fill="#0078d4" fill-rule="nonzero"></path>' +
      '        <path d="M27,20 C29.7614237,20 32,22.2385763 32,25 C32,27.7614237 29.7614237,30 27,30 C24.2385763,30 22,27.7614237 22,25 C22,22.2385763 24.2385763,20 27,20 Z M28.75,22 C28.0585938,22 27.5,22.5585938 27.5,23.25 C27.5,23.2929688 27.5039062,23.3398438 27.5078125,23.3828125 L26.1015625,24.0859375 C25.8789062,23.8789062 25.578125,23.75 25.25,23.75 C24.5585938,23.75 24,24.3085938 24,25 C24,25.6914062 24.5585938,26.25 25.25,26.25 C25.578125,26.25 25.8789062,26.1210938 26.1015625,25.9140625 L27.5078125,26.6171875 C27.5039062,26.6601562 27.5,26.7070312 27.5,26.75 C27.5,27.4414062 28.0585938,28 28.75,28 C29.4414062,28 30,27.4414062 30,26.75 C30,26.0585938 29.4414062,25.5 28.75,25.5 C28.421875,25.5 28.1210938,25.6289062 27.8984375,25.8359375 L26.4921875,25.1328125 C26.4960938,25.0898438 26.5,25.0429688 26.5,25 C26.5,24.9570312 26.4960938,24.9101562 26.4921875,24.8671875 L27.8984375,24.1640625 C28.1210938,24.3710938 28.421875,24.5 28.75,24.5 C29.4414062,24.5 30,23.9414062 30,23.25 C30,22.5585938 29.4414062,22 28.75,22 Z" id="ModColor_prim" fill="#0078d4"></path>' +
      '    </g>' +
      '</g>' +
    '</svg>';
}



const primaryButton = (): IButtonStyles => {
  const state = store.getState();
  if (!Utilities.isNANObject(state) && !Utilities.isNANObject(state.authentication) && !Utilities.isNANObject(state.authentication.theme) &&
    state.authentication.theme.AdvancedThemingEnabled) {
      const theme = state.authentication.theme;
      return {
        root: {
          border: '0px',
          backgroundColor: theme.ButtonPrimaryColor + ' !important',
          color: theme.ButtonPrimaryFontColor + ' !important',
          borderRadius: '2px',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: '600 !important',
        },
        rootHovered: {
          backgroundColor: theme.ButtonPrimaryHoverColor + ' !important',
          border: '0px',
        },
        rootExpanded: {
          backgroundColor: theme.ButtonPrimaryColor + ' !important',
          border: '0px',
        },
        rootFocused: {
          backgroundColor: theme.ButtonPrimaryColor + ' !important',
          border: '0px',
        },
      };
  }
  else
    return {
      root: {
        border: '0px',
        backgroundColor: '#f36928 !important',
        color: '#ffffff !important',
        borderRadius: '2px',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '600 !important',
      },
      rootHovered: {
        backgroundColor: '#d14d0f !important',
        border: '0px',
      },
      rootExpanded: {
        backgroundColor: '#f36928 !important',
        border: '0px',
      },
      rootFocused: {
        backgroundColor: '#f36928 !important',
        border: '0px',
      },
    };
}

const primaryButtonReverse = (): IButtonStyles => {
  const state = store.getState();
  if (!Utilities.isNANObject(state) && !Utilities.isNANObject(state.authentication) && !Utilities.isNANObject(state.authentication.theme) &&
    state.authentication.theme.AdvancedThemingEnabled) {
      const theme = state.authentication.theme;
      return {
        root: {
          border: '1px solid ' + theme.ButtonPrimaryColor + ' !important',
          backgroundColor: 'transparent !important',
          color: theme.ButtonPrimaryFontColor + ' !important',
          borderRadius: '2px',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: '600 !important',
        },
        rootHovered: {
          backgroundColor: 'transparent !important',
          border: '0px',
        },
        rootExpanded: {
          border: '1px solid ' + theme.ButtonPrimaryColor + ' !important',
          backgroundColor: 'transparent !important',
        },
        rootFocused: {
          border: '1px solid ' + theme.ButtonPrimaryColor + ' !important',
          backgroundColor: 'transparent !important',
        },
      };
  }
  else
    return {
      root: {
        border: '1px solid #f36928 !important',
        backgroundColor: 'transparent !important',
        color: '#323130 !important',
        borderRadius: '2px',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: '600 !important',
      },
      rootHovered: {
        border: '1px solid #f36928 !important',
        backgroundColor: 'transparent !important',
      },
      rootExpanded: {
        border: '1px solid #f36928 !important',
        backgroundColor: 'transparent !important',
      },
      rootFocused: {
        border: '1px solid #f36928 !important',
        backgroundColor: 'transparent !important',
      },
    }; 
};

const actionButton = (): IButtonStyles => {
  return {
    root: {
      textDecoration: 'underline',
      height: '16px'
    },
  };
}

const actionButtonEdit = (): IButtonStyles => {
  return {
    root: {
      color: '#0581e3 !important',
      fontSize: '14px',
      height: '16px'
    },
  };
}

const actionButtonRemove = (): IButtonStyles => {
  return {
    root: {
      color: '#000000 !important',
      fontSize: '12px',
      height: '14px'
    },
    icon: {
      color: '#000000', 
      fontSize: '12px'
    },
  };
}

const actionButtonFiles = (): IButtonStyles => {
  return {
    root: {
      color: '#000000 !important',
      fontSize: '12px',
      height: '16px',
    },
    rootHovered: {
      color: '#0078d4 !important',
    },
    icon: {
      color: '#000000', 
      fontSize: '12px'
    },
    flexContainer: {
      flexDirection: 'row-reverse'
    }
  };
}

const secondaryButton = (): IButtonStyles => {
  const state = store.getState();
  if (!Utilities.isNANObject(state) && !Utilities.isNANObject(state.authentication) && !Utilities.isNANObject(state.authentication.theme) &&
    state.authentication.theme.AdvancedThemingEnabled) {
      const theme = state.authentication.theme;
      return {
        root: {
          border: '0px',
          backgroundColor: theme.ButtonSecondaryColor + ' !important',
          color: theme.ButtonPrimaryFontColor + ' !important'
        },
        rootHovered: {
          backgroundColor: theme.ButtonSecondaryHoverColor + ' !important',
          border: '0px',
        },
        rootExpanded: {
          backgroundColor: theme.ButtonSecondaryColor + ' !important',
          border: '0px',
        },
      };
  }
  else
    return {
      /*root: {
        border: '0px',
        backgroundColor: '#141b62 !important',
        color: '#FFFFFF !important'
      },
      rootHovered: {
        backgroundColor: '#3f48cc !important',
        border: '0px',
      },  */
    };
}

const DialogStyle = () => {
  const state = store.getState();
  if (!Utilities.isNANObject(state) && !Utilities.isNANObject(state.authentication) && !Utilities.isNANObject(state.authentication.theme) &&
    state.authentication.theme.AdvancedThemingEnabled) {
      const theme = state.authentication.theme;
      return {
        main: {
          background: theme.PopupBackgroundColor,
          width: '380px !important',
          maxWidth: '380px !important'
        }
      }
  }
  else
    return {
      main: {
        width: '380px !important',
        maxWidth: '380px !important'
      }      
    };
}


export const appStyles = {
  get globalStyles() {
    return globalStyles();
  },
  get primaryButton() {
    return primaryButton();
  },
  get secondaryButton() {
    return secondaryButton();
  },
  get primaryButtonReverse() {
    return primaryButtonReverse();
  },
  get actionButton() {
    return actionButton();
  },
  get actionButtonEdit() {
    return actionButtonEdit();
  },
  get actionButtonRemove() {
    return actionButtonRemove();
  },
  get actionButtonFiles() {
    return actionButtonFiles();
  },
  DialogStyle,
  checkBoxStyle,
  textBoxStyle,
  dropDownStyle,
  choiceStyle,
  toggleStyle,
  getProtectSvg,
  getDateSvg,
  getShareSvg
}